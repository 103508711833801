import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../../../api";

const initialState = {
  data: [],
  totalPages: 0,
  totalCount: 0,
  loading: false,
  errors: null,
};

export const getReminders = createAsyncThunk(
  "users/getReminders",
  async (data, thunk) => {
    try {
      let response = await API.users.reminders(data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getQueuedReminders = createAsyncThunk(
  "users/queuedReminders",
  async (data, thunk) => {
    try {
      let response = await API.users.queuedReminders(data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const remindersSlice = createSlice({
  name: "reminders",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getReminders.pending]: (state) => {
      state.loading = true;
    },
    [getReminders.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.reminders;
      state.totalCount = action.payload.total_count;
      state.totalPages = action.payload.total_pages;
    },
    [getReminders.rejected]: (state, action) => {
      state.loading = false;
      state.data = [];
      state.totalPages = 0;
      state.errors = ["Data not found!"];
    },
  },
});

export const { clearErrors } = remindersSlice.actions;

export const remindersSelector = (state) => state.reminders;

export default remindersSlice.reducer;
