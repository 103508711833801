import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotFound } from "./features/404/NotFound";
import { Login } from "./features/auth/Login";
import { Privacy } from "./features/legal/Privacy";
import { Terms } from "./features/legal/Terms";
import { Nav } from "./features/nav/Nav";

export const Router = () => (
  <Routes>
    <Route exact path="/" element={<Nav />} />
    <Route exact path="/login" element={<Login />} />
    <Route exact path="/profile" element={<Nav />} />
    <Route exact path="/orgs" element={<Nav />} />
    <Route exact path="/orgs/:id" element={<Nav />} />
    <Route exact path="/discover" element={<Nav />} />
    <Route exact path="/discover/:id" element={<Nav />} />
    <Route exact path="/users" element={<Nav />} />
    <Route exact path="/users/:id" element={<Nav />} />
    <Route exact path="/nodes" element={<Nav />} />
    <Route exact path="/nodes/:id" element={<Nav />} />
    <Route exact path="/services" element={<Nav />} />
    <Route exact path="/conversations" element={<Nav />} />
    <Route exact path="/conversations/:id" element={<Nav />} />
    <Route exact path="/chat" element={<Nav />} />
    <Route exact path="/announcements" element={<Nav />} />
    <Route exact path="/support" element={<Nav />} />
    <Route exact path="/workers" element={<Nav />} />
    <Route exact path="/terms" element={<Terms />} />
    <Route exact path="/privacy" element={<Privacy />} />
    <Route exact path="*" element={<NotFound />} />
  </Routes>
);
