import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../../../api";

const initialState = {
  gifts: [],
  loading: false,
  errors: null,
  totalPages: 0,
  totalCount: 0,
};

export const getGifts = createAsyncThunk("gifts/index", async (data, thunk) => {
  try {
    let response = await API.orgs.gifts.index(data.id, data.page, data.per);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const updateBulkGifts = createAsyncThunk(
  "gifts/updateBulk",
  async (data, thunk) => {
    try {
      let response = await API.orgs.gifts.bulkUpdate(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const giftsSlice = createSlice({
  name: "gifts",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getGifts.pending]: (state) => {
      state.loading = true;
    },
    [getGifts.fulfilled]: (state, action) => {
      state.loading = false;
      state.gifts = action.payload.gifts;
      state.totalPages = action.payload.total_pages;
      state.totalCount = action.payload.total_count;
      state.remainingCount = action.payload.remaining_count;
    },
    [getGifts.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const { clearErrors } = giftsSlice.actions;

export const giftsSelector = (state) => state.gifts;

export default giftsSlice.reducer;
