import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  data: {},
  loading: false,
  errors: null,
  admins: [],
};

export const getInfo = createAsyncThunk("getInfo", async (data, thunk) => {
  try {
    let response = await API.nodes.get(data.id);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const addAdmins = createAsyncThunk("addAdmins", async (data, thunk) => {
  try {
    let response = await API.nodes.admins.addAdmins(data.id, data.data);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getInfo.pending]: (state) => {
      state.data = {};
      state.loading = true;
    },
    [getInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.admins = action.payload.admins;
    },
    [getInfo.rejected]: (state, action) => {
      state.loading = false;
      state.data = null;
      state.errors = ["Error while retrieving details"];
    },
    [addAdmins.pending]: (state) => {
      state.loading = true;
    },
    [addAdmins.fulfilled]: (state, action) => {
      state.loading = false;
      state.admins = action.payload.admins;
    },
    [addAdmins.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors
        ? action.payload.errors
        : ["Admin could not be added. Try again later!"];
    },
  },
});

export const { clearErrors } = infoSlice.actions;

export const infoSelector = (state) => state.info;

export default infoSlice.reducer;
