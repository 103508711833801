import { Avatar } from "antd";
import styles from "./Logo.module.css";

export function Logo(props) {
  return (
    <>
      {props.data.src ? (
        <Avatar
          className={
            props.size === "xxxl"
              ? styles.logoXXXl
              : props.size === "xxl"
              ? styles.logoXXl
              : props.size === "xl"
              ? styles.logoXl
              : props.size === "lg"
              ? styles.logoLg
              : styles.logo
          }
          src={props.data.src}
        />
      ) : (
        <Avatar
          className={`${
            props.size === "xxxl"
              ? styles.logoXXXl
              : props.size === "xxl"
              ? styles.logoXXl
              : props.size === "xl"
              ? styles.logoXl
              : props.size === "lg"
              ? styles.logoLg
              : styles.logo
          } ${props.type === "node" ? styles.emptyNode : styles.empty}`}
        >
          {props.data.name.charAt(0).toUpperCase()}
        </Avatar>
      )}
    </>
  );
}
