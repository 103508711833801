import {
  CloudUploadOutlined,
  DownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useCSVReader } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PilotStoresGE } from "../../../../constants";
import {
  createEmployee,
  downloadEmployees,
  employeesSelector,
  getEmployees,
} from "./employeesSlice";
import { EmployeeUpdate } from "./EmployeeUpdate";

const PAGE_SIZE = 50;

export function Employees(props) {
  const dispatch = useDispatch();
  const { CSVReader } = useCSVReader();
  const {
    employees,
    loading,
    errors,
    totalPages,
    totalCount,
    currentCount,
    registeredCount,
    terminatedCount,
  } = useSelector(employeesSelector);
  const [uploadOpened, setUploadOpened] = useState(false);
  const [uploadData, setUploadData] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [createEmployeeOpened, setCreateEmployeeOpened] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    if (props.id) {
      loadEmployee(pageNum);
    }
  }, [props.id, dispatch]);

  const onPageChange = (page) => {
    setPageNum(page);
    loadEmployee(page);
  };

  const loadEmployee = (page) => {
    dispatch(
      getEmployees({
        id: props.id,
        page: page,
        per: PAGE_SIZE,
      })
    );
  };

  const addEmployee = (values) => {
    let data = values;
    data.joined = data.joined.format("YYYY-MM-DD");
    data.store = PilotStoresGE[data.store_id];
    console.log(data);
    dispatch(
      createEmployee({
        id: props.id,
        data: data,
      })
    ).then((res) => {
      if (res.type === "employees/create/fulfilled") {
        message.success("Employee created successfully");
        setCreateEmployeeOpened(false);
        loadEmployee(1);
      } else {
        message.error("Failed to create employee");
      }
    });
  };

  const downloadCsv = () => {
    dispatch(
      downloadEmployees({
        id: props.id,
        format: "csv",
      })
    ).then((res) => {
      if (res.type === "employees/download/fulfilled") {
        const url = window.URL.createObjectURL(new Blob([res.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `employees - ${new Date().toLocaleString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        message.error("Failed to download employees data");
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        title: "User ID",
        dataIndex: "user_id",
        key: "user_id",
        render: (id) => {
          return id ? <Link to={`/users/${id}`}>{id}</Link> : "Not Registered";
        },
      },
      {
        title: "Member ID",
        dataIndex: ["meta", "member_id"],
        key: "member_id",
      },
      {
        title: "Store ID",
        dataIndex: ["meta", "store_id"],
        key: "store_id",
      },
      {
        title: "Store",
        dataIndex: ["meta", "store"],
        key: "store",
      },
      {
        title: "Joined",
        dataIndex: ["meta", "joined"],
        key: "joined",
      },
      {
        title: "Terminated",
        dataIndex: ["meta", "terminated"],
        key: "terminated",
        render: (t) => t && <div style={{ color: "#dc4446" }}>{t}</div>,
      },
    ],
    []
  );

  return (
    <>
      <div>
        <Row align="middle" gutter={16}>
          <Col span={10}>
            [Pilot] -- In Scope: <span className="fw-600">{currentCount}</span>,
            Registered: <span className="fw-600">{registeredCount}</span>,
            Terminated: <span className="fw-600">{terminatedCount}</span>
          </Col>

          <Col span={8} className="ta-r">
            {props.id === 167 ? (
              <div className="c-subtext ta-r">Upload file in main org</div>
            ) : (
              <Space size={16}>
                {props?.lastUpdated && (
                  <div className="fs-1">({props?.lastUpdated})</div>
                )}
                <Tooltip
                  title="CSV file with headers - employee_id, joined_date, store_id, store, in_pilot"
                  color="#4867b1"
                >
                  <div>
                    <CSVReader
                      onUploadAccepted={(results) => {
                        setUploadData(results.data);
                        setUploadOpened(true);
                      }}
                    >
                      {({ getRootProps }) => {
                        return (
                          <Button
                            icon={<CloudUploadOutlined />}
                            {...getRootProps()}
                          >
                            Employee Bulk Update
                          </Button>
                        );
                      }}
                    </CSVReader>
                  </div>
                </Tooltip>
              </Space>
            )}
          </Col>
          <Col span={4} className="ta-l">
            <Button
              icon={<PlusOutlined />}
              onClick={() => setCreateEmployeeOpened(true)}
            >
              Create Employee
            </Button>
          </Col>
          <Col span={2} className="ta-r">
            <Popconfirm
              title="Are you sure you want to export all employee data in the pilot?"
              onConfirm={downloadCsv}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DownloadOutlined />}>Export</Button>
            </Popconfirm>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Table
            size="small"
            columns={columns}
            dataSource={employees.map((l, i) => {
              return { ...l, ...{ key: i } };
            })}
            className="mt-20"
            pagination={{
              position: ["bottomRight"],
              onChange: onPageChange,
              pageSize: PAGE_SIZE,
              total: totalPages * PAGE_SIZE,
              showSizeChanger: false,
              current: pageNum,
              showTotal: () => (
                <span className="fs-2 c-subtext">{`${totalCount} ${
                  totalCount === 1 ? "employee in pilot" : "employees in pilot"
                }`}</span>
              ),
            }}
          />
        </Spin>
      </div>

      <Modal
        title="Create Employee"
        open={createEmployeeOpened}
        footer={null}
        destroyOnClose={true}
        width={450}
        onCancel={() => setCreateEmployeeOpened(false)}
      >
        <div className="mt-20">
          <Alert
            message="This user will be marked as terminated if they are not included in your next employee bulk update."
            type="warning"
            showIcon
          />
        </div>
        <Form
          requiredMark={false}
          layout="vertical"
          className="mt-20"
          onFinish={addEmployee}
        >
          <Form.Item
            label="Team Member ID"
            name="member_id"
            rules={[{ required: true, message: "Please input team member id" }]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="Store"
            name="store_id"
            rules={[{ required: true, message: "Please input store" }]}
          >
            <Select
              className="w-100"
              showSearch={true}
              options={Object.keys(PilotStoresGE)
                .sort((a, b) => a.localeCompare(b))
                .map((key) => {
                  return { label: PilotStoresGE[key], value: key };
                })}
            />
          </Form.Item>
          <Form.Item
            label="Joined Date"
            name="joined"
            rules={[{ required: true, message: "Please input joined date" }]}
          >
            <DatePicker
              className="w-100"
              format="YYYY-MM-DD"
              placeholder={null}
            />
          </Form.Item>
          <Form.Item className="ta-r">
            <Button type="primary" htmlType="submit" className="mt-10">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Employee Upload"
        open={uploadOpened}
        footer={null}
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        width={1000}
        style={{
          top: 32,
        }}
      >
        <div className="pt-10">
          <EmployeeUpdate
            id={props.id}
            data={uploadData}
            onSuccess={() => {
              setUploadOpened(false);
              setUploadData([]);
              dispatch(
                getEmployees({
                  id: props.id,
                  page: pageNum,
                  per: PAGE_SIZE,
                })
              );
            }}
            onCancel={() => {
              setUploadOpened(false);
              setUploadData([]);
            }}
          />
        </div>
      </Modal>
    </>
  );
}
