import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../../../api";

const initialState = {
  admins: [],
  loading: false,
  errors: null,
};

export const getAdmins = createAsyncThunk("getAdmins", async (data, thunk) => {
  try {
    let response = await API.orgs.admins.getAdmins(data.id);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const addAdmins = createAsyncThunk("addAdmins", async (data, thunk) => {
  try {
    let response = await API.orgs.admins.addAdmins(data.id, data.data);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const removeAdmins = createAsyncThunk(
  "removeAdmins",
  async (data, thunk) => {
    try {
      let response = await API.orgs.admins.removeAdmins(data.id, data.adminId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const adminsSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getAdmins.pending]: (state) => {
      state.dataLoading = true;
      state.admins = [];
    },
    [getAdmins.fulfilled]: (state, action) => {
      state.dataLoading = false;
      state.admins = action.payload.admins;
    },
    [getAdmins.rejected]: (state, action) => {
      state.dataLoading = false;
      state.admins = [];
      state.errors = ["Data not found!"];
    },
    [addAdmins.pending]: (state) => {
      state.loading = true;
    },
    [addAdmins.fulfilled]: (state, action) => {
      state.loading = false;
      state.admins = action.payload.admins;
    },
    [addAdmins.rejected]: (state, action) => {
      state.loading = false;
      state.errors = ["Admin could not be added. Try again later!"];
    },
    [removeAdmins.pending]: (state) => {
      state.loading = true;
    },
    [removeAdmins.fulfilled]: (state, action) => {
      state.loading = false;
      state.admins = action.payload.admins;
    },
    [removeAdmins.rejected]: (state, action) => {
      state.loading = false;
      state.errors = [
        "Admin could not be removed. Min. of one admin is required!",
      ];
    },
  },
});

export const { clearErrors } = adminsSlice.actions;

export const adminsSelector = (state) => state.admins;

export default adminsSlice.reducer;
