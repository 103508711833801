import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../../../api";

const initialState = {
  data: [],
  loading: false,
  totalPages: 0,
  totalCount: 0,
};

export const getNodes = createAsyncThunk("getNodes", async (data, thunk) => {
  try {
    let response = await API.orgs.personalize.getPersonalisedNodes(
      data.id,
      data.page
    );
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const personalizeSlice = createSlice({
  name: "personalize",
  initialState,
  extraReducers: {
    [getNodes.pending]: (state) => {
      state.loading = true;
    },
    [getNodes.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.nodes.map((s) => {
        s.key = s.id;
        return s;
      });
      state.totalPages = action.payload.total_pages;
      state.totalCount = action.payload.total_count;
    },
    [getNodes.rejected]: (state, action) => {
      state.loading = false;
      state.data = [];
      state.totalPages = 0;
      state.totalCount = 0;
      state.errors = ["Data not found!"];
    },
  },
});

export const { clearErrors } = personalizeSlice.actions;

export const personalizeSelector = (state) => state.personalize;

export default personalizeSlice.reducer;
