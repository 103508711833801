import { CloseCircleOutlined } from "@ant-design/icons";
import { Col, Row, Table, message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CATEGORIES } from "../../categories";
import { clearData, dataSelector, getData } from "./dataSlice";

export function Data(props) {
  const dispatch = useDispatch();
  const { data, loading, errors } = useSelector(dataSelector);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  useEffect(() => {
    if (props.id || props.userId) {
      dispatch(clearData());
      let scope = { nodeId: props.id };
      if (props.userId) {
        scope = { userId: props.userId };
      }
      dispatch(getData(scope));
    }
  }, [dispatch, props.id, props.userId]);

  const getStatus = (status) => {
    switch (status) {
      case 2:
        return "Analyzing...";
      case 3:
        return "Analyzed";
      case 4:
        return "Approved";
      default:
        return "Uploaded";
    }
  };

  const reportColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, record) => (
        <>
          <a href={record.url} target="_blank" rel="noreferrer">
            {name}
          </a>
        </>
      ),
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <>{getStatus(status)}</>,
    },
  ];

  const otherColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, record) => (
        <>
          <a href={record.url} target="_blank" rel="noreferrer">
            {name}
          </a>
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "sec_file_type",
      key: "sec_file_type",
      render: (sec_file_type) => (
        <>{CATEGORIES.filter((c) => c.value === sec_file_type)[0]?.label}</>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <>{getStatus(status)}</>,
    },
  ];

  const receiptColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, record) => (
        <>
          <a href={record.url} target="_blank" rel="noreferrer">
            {name}
          </a>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <>{getStatus(status)}</>,
    },
    {
      title: "No. Extracted",
      dataIndex: "needs_c",
      key: "needs_c",
    },
  ];

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Table
            pagination={{ position: ["none", "none"] }}
            size="small"
            bordered
            loading={loading}
            columns={reportColumns}
            dataSource={data
              .filter((d) => d.file_type === 2)
              .sort((a, b) => b.year - a.year)}
            title={() => "Annual Reports"}
          />
        </Col>
        <Col span={12}>
          <Table
            pagination={{ position: ["none", "none"] }}
            size="small"
            bordered
            loading={loading}
            columns={otherColumns}
            dataSource={data
              .filter((d) => d.file_type === 3)
              .sort((a, b) => b.year - a.year)}
            title={() => "Other"}
          />
        </Col>
        {!props.userId && (
          <Col span={12}>
            <Table
              pagination={{ position: ["none", "none"] }}
              size="small"
              bordered
              loading={loading}
              columns={reportColumns}
              dataSource={data
                .filter((d) => d.file_type === 4)
                .sort((a, b) => b.year - a.year)}
              title={() => "990 Forms"}
            />
          </Col>
        )}
        {!props.userId && (
          <Col span={12}>
            <Table
              pagination={{ position: ["none", "none"] }}
              size="small"
              bordered
              loading={loading}
              columns={receiptColumns}
              dataSource={data.filter((d) => d.file_type === 1)}
              title={() => "Receipts"}
            />
          </Col>
        )}
      </Row>
    </>
  );
}
