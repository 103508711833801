import { message, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getServiceReviews } from "./servicesSlice";

const PAGE_SIZE = 25;

export function Reviews(props) {
  const dispatch = useDispatch();
  const [reviews, setReviews] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    if (props.id) {
      loadReviews(pageNum);
    }
  }, [props.id]);

  const loadReviews = (page) => {
    dispatch(getServiceReviews({ id: props.id, page, per: PAGE_SIZE })).then(
      (res) => {
        if (res.type === "services/getReviews/fulfilled") {
          setReviews(res.payload.reviews);
          setTotalPages(res.payload.total_pages);
          setTotalCount(res.payload.total_count);
        } else {
          message.error("Failed to load reviews");
        }
      }
    );
  };

  const onPageChange = (page) => {
    setPageNum(page);
    loadReviews(page);
  };

  return (
    <Table
      bordered
      size="small"
      className="mt-25"
      columns={[
        {
          title: "User",
          dataIndex: "user_id",
          key: "user_id",
          render: (id) => (
            <>
              <Link to={`/users/${id}`}>{id}</Link>
            </>
          ),
        },
        {
          title: "Rating",
          dataIndex: "rating",
          key: "rating",
        },
        {
          title: "Comment",
          dataIndex: "comment",
          key: "comment",
        },
        {
          title: "Reviewed At",
          dataIndex: "created_at",
          key: "created_at",
          render: (at) => new Date(at).toLocaleString(),
        },
      ]}
      dataSource={reviews.map((l, i) => {
        return { ...l, ...{ key: i } };
      })}
      pagination={{
        position: ["bottomRight"],
        onChange: onPageChange,
        pageSize: PAGE_SIZE,
        total: totalPages * PAGE_SIZE,
        showSizeChanger: false,
        current: pageNum,
        showTotal: () => (
          <span className="fs-2 c-subtext">{`${totalCount} ${
            totalCount === 1 ? "review" : "reviews"
          }`}</span>
        ),
      }}
    />
  );
}
