import { Table } from "antd";
import { Link } from "react-router-dom";

export const Permissions = {
  assistant: 1,
  data: 2,
  team: 3,
};

export function Admins(props) {
  const getRole = (p) => {
    if (p.includes(Permissions.data) && p.includes(Permissions.team)) {
      return "Admin";
    } else if (p.includes(Permissions.data)) {
      return "Editor";
    } else {
      return "Viewer";
    }
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (_, record) => (
        <>
          {record?.user?.f_name && (
            <Link to={`/users/${record?.user?.id}`}>
              {`${record?.user?.f_name} ${record?.user?.l_name}`}
            </Link>
          )}
          &nbsp;
          {record.request
            ? "(Incoming Request)"
            : !record.user.id
            ? "(Invited User)"
            : ""}
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: ["user", "email"],
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "permissions",
      key: "permissions",
      render: (p) => getRole(p),
    },
  ];

  return (
    <Table
      pagination={{ position: ["none", "none"] }}
      dataSource={props.admins.map((a) => ({
        ...a,
        key: a.id,
      }))}
      bordered
      size="small"
      columns={columns}
    />
  );
}
