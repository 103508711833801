import {
  FrownFilled,
  LoadingOutlined,
  MehFilled,
  SearchOutlined,
  SmileFilled,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Chats } from "../../../conversations/Chats";
import {
  clearConversations,
  conversationsSelector,
  getConversations,
} from "../../../conversations/conversationsSlice";

const PAGE_SIZE = 50;
const { Search } = Input;

export function Conversations(props) {
  const dispatch = useDispatch();
  const {
    data: conversations,
    loading,
    errors,
    totalPages,
    totalCount,
  } = useSelector(conversationsSelector);
  const [pageNum, setPageNum] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [currentConversation, setCurrentConversation] = useState();
  const [chatsOpened, setChatsOpened] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    if (props.id || props.userId) {
      dispatch(clearConversations());
      let filters = { ...props.filters };
      if (props.id) {
        filters.node_id = props.id;
      }
      if (props.userId) {
        filters.user_id = props.userId;
      }
      dispatch(
        getConversations({
          page: 1,
          search: "",
          filters: filters,
        })
      );
    }
  }, [props.id, props.userId]);

  const getTimeDiff = (start, finish) => {
    const diff = Math.abs(new Date(finish) - new Date(start));
    const m = Math.floor(diff / 60000);
    const s = Math.floor((diff % 60000) / 1000);
    return `${m}m ${s}s`;
  };

  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <>
            <span
              className="link"
              onClick={() => {
                setCurrentConversation(record);
                setChatsOpened(true);
              }}
            >
              {text}
            </span>
          </>
        ),
      },
      {
        title: "Duration",
        key: "duration",
        render: (record) => (
          <>
            {record.failed ? (
              <Tag color="red">Error</Tag>
            ) : record.control_id ? (
              record.finished_at ? (
                record.name === "Casey Call" ? (
                  <Tag>
                    {getTimeDiff(record.created_at, record.finished_at)}
                  </Tag>
                ) : (
                  <></>
                )
              ) : (
                <Tag icon={<LoadingOutlined />} color="green">
                  Live
                </Tag>
              )
            ) : (
              <></>
            )}
          </>
        ),
      },
      {
        title: "Messages",
        dataIndex: "chats_c",
        key: "chats_c",
      },
      {
        title: "From",
        dataIndex: "user_id",
        key: "user_id",
        render: (id) => (
          <>
            <Link to={`/users/${id}`}>{id}</Link>
          </>
        ),
      },
      {
        title: "Node ID",
        dataIndex: "node_id",
        key: "node_id",
        render: (id) => (
          <>
            <Link to={`/nodes/${id}`}>{id}</Link>
          </>
        ),
      },
      {
        title: "Last message at",
        dataIndex: "latest_chat_at",
        key: "latest_chat_at",
        render: (text) => (
          <div size="middle">
            <div>{new Date(text).toLocaleString()}</div>
          </div>
        ),
      },
      {
        title: "Sentiment",
        dataIndex: "sentiment",
        key: "sentiment",
        render: (s) => {
          if (s?.emotion) {
            return (
              s.emotion && (
                <Tooltip title={s.reason} color="#4867b1">
                  {s.emotion === "positive" ? (
                    <SmileFilled
                      className="fs-3"
                      style={{ color: "#49aa19" }}
                    />
                  ) : s.emotion === "negative" ? (
                    <FrownFilled style={{ color: "#dc4446" }} />
                  ) : (
                    <MehFilled style={{ color: "#faad14" }} />
                  )}
                </Tooltip>
              )
            );
          }
        },
      },
    ],
    []
  );

  const search = (value) => {
    setSearchString(value);
    setPageNum(1);
    let filters = { ...props.filters };
    if (props.id) {
      filters.node_id = props.id;
    }
    if (props.userId) {
      filters.user_id = props.userId;
    }
    dispatch(
      getConversations({
        page: 1,
        search: value,
        filters: filters,
      })
    );
  };

  const onPageChange = (page) => {
    setPageNum(page);
    let filters = { ...props.filters };
    if (props.id) {
      filters.node_id = props.id;
    }
    if (props.userId) {
      filters.user_id = props.userId;
    }
    dispatch(
      getConversations({
        page: page,
        search: searchString,
        filters: filters,
      })
    );
  };

  return (
    <>
      <div>
        <Row className="mb-20">
          <Col span={24}>
            <Search
              allowClear={false}
              size="large"
              onSearch={search}
              enterButton={
                <Button type="primary" ghost icon={<SearchOutlined />} />
              }
              placeholder="Search id, user id, node id or name..."
            />
          </Col>
        </Row>
        <div className="mt-30">
          <Table
            loading={loading}
            bordered
            size="small"
            columns={columns}
            dataSource={conversations.map((l, i) => {
              return { ...l, ...{ key: i } };
            })}
            pagination={{
              position: ["bottomRight"],
              onChange: onPageChange,
              pageSize: PAGE_SIZE,
              total: totalPages * PAGE_SIZE,
              showSizeChanger: false,
              current: pageNum,
              showTotal: (total) => (
                <span className="fs-2 c-subtext">{`${totalCount} ${
                  totalCount === 1 ? "conversation" : "conversations"
                }`}</span>
              ),
            }}
          ></Table>
        </div>
      </div>
      <Modal
        destroyOnClose
        title={`${currentConversation?.id} - ${currentConversation?.name}`}
        onCancel={() => setChatsOpened(false)}
        open={chatsOpened}
        footer={null}
        width={1000}
        style={{ top: 64 }}
      >
        <Chats conversation={currentConversation} />
        <div className="mt-10"></div>
      </Modal>
    </>
  );
}
