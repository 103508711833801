import { Button, Col, message, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateBulkEmployees } from "./employeesSlice";

const UpdateFields = ["member_id", "joined", "store_id", "store", "in_pilot"];

export function EmployeeUpdate(props) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (props.id) {
      setData(
        props.data
          .filter((d, i) => i !== 0 && d.length === UpdateFields.length)
          .map((d, i) => {
            let formatted = { key: i };
            d.forEach((f, j) => {
              formatted[UpdateFields[j]] = f;
            });
            return formatted;
          })
      );
    }
  }, [props.id, props.data]);

  const getColumns = () => {
    return UpdateFields.map((f) => {
      let obj = {
        title: f,
        dataIndex: f,
        key: f,
      };
      return obj;
    });
  };

  const update = () => {
    setProcessing(true);
    dispatch(
      updateBulkEmployees({
        id: props.id,
        data: { employees: data.map(({ key, ...rest }) => rest) },
      })
    ).then((res) => {
      setProcessing(false);
      if (res.type === "employees/updateBulk/rejected") {
        message.error("Error updating employees");
      } else {
        props.onSuccess();
      }
    });
  };

  return (
    <>
      <Table
        dataSource={data}
        columns={getColumns()}
        size="small"
        pagination={{
          position: ["bottomRight"],
          showTotal: (total) => `${total} fields to update`,
        }}
      />
      <Row className="mt-30">
        <Col span={12}>
          {!processing && <Button onClick={props.onCancel}>Cancel</Button>}
        </Col>
        <Col span={12} className="ta-r">
          <Button type="primary" onClick={update} loading={processing}>
            Update
          </Button>
        </Col>
      </Row>
    </>
  );
}
