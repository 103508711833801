import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Metric } from "../../components/Metric/Metric";
import { metricsSelector, refreshMetrics } from "../nav/navSlice";
import {
  getSupportRequests,
  supportRequestsSelector,
  updateSupportRequest,
} from "./supportSlice";

const PAGE_SIZE = 50;
const { TextArea } = Input;

export function Support() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { data: metrics, loading: metricsLoading } =
    useSelector(metricsSelector);
  const {
    data: requests,
    loading,
    errors,
    totalPages,
    totalCount,
  } = useSelector(supportRequestsSelector);
  const [pageNum, setPageNum] = useState(1);
  const [editOpened, setEditOpened] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(
      getSupportRequests({
        page: pageNum,
        per: PAGE_SIZE,
        search: searchString,
        filters: filters,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      refreshMetrics({
        filter: "support_requests",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (currentRequest?.id) {
      form.setFieldsValue({
        notes: currentRequest.notes,
        status: currentRequest.status,
      });
    }
  }, [currentRequest]);

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(
      getSupportRequests({
        page,
        per: PAGE_SIZE,
        search: searchString,
        filters: filters,
      })
    );
  };

  const updateRequest = (values) => {
    dispatch(
      updateSupportRequest({ id: currentRequest.id, data: values })
    ).then((res) => {
      if (res.type === "support/update/fulfilled") {
        setEditOpened(false);
        setCurrentRequest(null);
        form.resetFields();
        dispatch(
          getSupportRequests({
            page: pageNum,
            per: PAGE_SIZE,
            search: searchString,
            filters: filters,
          })
        );
        dispatch(
          refreshMetrics({
            filter: "support_requests",
          })
        );
      } else {
        message.error("Error while updating request");
      }
    });
  };

  const search = () => {
    setPageNum(1);
    dispatch(
      getSupportRequests({
        page: 1,
        per: PAGE_SIZE,
        search: searchString,
        filters: filters,
      })
    );
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Type",
      dataIndex: "support_type",
      key: "support_type",
      render: (supportType) => (
        <>{supportType === 2 ? "Terminated" : "User Requested"}</>
      ),
    },
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
      render: (id) => <Link to={`/users/${id}`}>{id}</Link>,
    },
    {
      title: "Conversation ID",
      dataIndex: "conversation_id",
      key: "conversation_id",
      render: (id) => <Link to={`/conversations/${id}`}>{id}</Link>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) =>
        status === 1 ? (
          <Tag icon={<ExclamationCircleOutlined />} color="error">
            New
          </Tag>
        ) : status === 2 ? (
          <Tag icon={<SyncOutlined spin />} color="processing">
            In Progress
          </Tag>
        ) : (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Closed
          </Tag>
        ),
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => new Date(created_at).toLocaleString(),
    },
    {
      title: "",
      key: "update",
      render: (req) => (
        <Button
          size="small"
          type="link"
          onClick={() => {
            setCurrentRequest(req);
            setEditOpened(true);
          }}
        >
          Update
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="container">
        <div className="mb-15">
          <Row justify="space-between" wrap>
            <Col>
              <span className="fs-5 fw-300">Support Requests</span>
            </Col>
          </Row>
        </div>
        <div className="mb-30">
          <Row gutter={24}>
            <Col span={6}>
              <Metric
                title="New Requests"
                current={metrics["logged_c"]}
                loading={metricsLoading}
              />
            </Col>
            <Col span={6}>
              <Metric
                title="In Progress Requests"
                current={metrics["active_c"]}
                loading={metricsLoading}
              />
            </Col>
          </Row>
        </div>
        <div className="container bg-white b-shadow">
          <Row className="mb-20" gutter={12}>
            <Col span={4}>
              <Select
                allowClear
                placeholder="Type"
                className="w-100"
                onChange={(val) =>
                  setFilters({ ...filters, support_type: val })
                }
                options={[
                  {
                    value: 1,
                    label: "User Requested",
                  },
                  {
                    value: 2,
                    label: "Terminated",
                  },
                ]}
              />
            </Col>
            <Col span={3}>
              <Select
                allowClear
                placeholder="Status"
                className="w-100"
                onChange={(val) => setFilters({ ...filters, status: val })}
                options={[
                  {
                    value: 1,
                    label: "New",
                  },
                  {
                    value: 2,
                    label: "In Progress",
                  },
                  {
                    value: 3,
                    label: "Closed",
                  },
                ]}
              />
            </Col>
            <Col span={14}>
              <Input
                placeholder="Search id or user id..."
                onChange={(e) => setSearchString(e.target.value)}
              />
            </Col>
            <Col span={3}>
              <Button
                icon={<SearchOutlined />}
                className="w-100"
                onClick={search}
                loading={loading}
              >
                Search
              </Button>
            </Col>
          </Row>
          <Table
            loading={loading}
            bordered
            size="small"
            columns={columns}
            className="mt-30"
            dataSource={requests.map((l, i) => {
              return { ...l, ...{ key: i } };
            })}
            pagination={{
              position: ["bottomRight"],
              onChange: onPageChange,
              pageSize: PAGE_SIZE,
              total: totalPages * PAGE_SIZE,
              showSizeChanger: false,
              current: pageNum,
              showTotal: (total) => (
                <span className="fs-2 c-subtext">{`${totalCount} ${
                  totalCount === 1 ? "node" : "nodes"
                }`}</span>
              ),
            }}
          />
        </div>
      </div>

      <Modal
        title={`Request #${currentRequest?.id}`}
        open={editOpened}
        footer={null}
        onCancel={() => {
          setEditOpened(false);
          setCurrentRequest(null);
          form.resetFields();
        }}
      >
        <div className="mt-30">
          <Form form={form} requiredMark={false} onFinish={updateRequest}>
            <Form.Item name="notes">
              <TextArea autoSize={true} placeholder="Notes" />
            </Form.Item>
            <Form.Item name="status">
              <Radio.Group
                options={[
                  { label: "New", value: 1 },
                  { label: "In Progress", value: 2 },
                  { label: "Closed", value: 3 },
                ]}
                optionType="button"
              />
            </Form.Item>
            <Form.Item className="ta-r">
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
