import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../../../api";

const initialState = {
  logs: {},
  totalPages: 0,
  loading: true,
  errors: null,
};

export const getLogs = createAsyncThunk("logs", async (data, thunk) => {
  try {
    let response = await API.orgs.logs.getLogs(
      data.id,
      data.from,
      data.to,
      data.scope,
      data.page
    );
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const exportLogs = createAsyncThunk(
  "logs/export",
  async (data, thunk) => {
    try {
      let response = await API.orgs.logs.exportLogs(
        data.id,
        data.from,
        data.to,
        data.scope
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getLogs.pending]: (state) => {
      state.loading = true;
      state.logs = {};
    },
    [getLogs.fulfilled]: (state, action) => {
      state.loading = false;
      state.logs = action.payload.logs;
      state.totalPages = action.payload.total_pages;
    },
    [getLogs.rejected]: (state, action) => {
      state.loading = false;
      state.logs = {};
      state.totalPages = 0;
      state.errors = ["Error during data retrieval!"];
    },
    [exportLogs.pending]: (state) => {
      state.loading = true;
    },
    [exportLogs.fulfilled]: (state, action) => {
      state.loading = false;
      const url = window.URL.createObjectURL(new Blob([action.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `org-${action.meta.arg.id}-${action.meta.arg.scope}-${action.meta.arg.from}.csv`
      );
      document.body.appendChild(link);
      link.click();
    },
    [exportLogs.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
  },
});

export const { clearErrors } = activitySlice.actions;

export const activitySelector = (state) => state.activity;

export default activitySlice.reducer;
