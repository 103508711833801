import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../../../api";

const initialState = {
  data: [],
  loading: false,
  errors: null,
};

export const getData = createAsyncThunk("data/index", async (data, thunk) => {
  try {
    let response = await API.data.index(data);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    clearData: (state) => {
      state.data = [];
    },
  },
  extraReducers: {
    [getData.pending]: (state) => {
      state.loading = true;
    },
    [getData.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data_sources.map((n) => {
        return { ...n, key: n.id };
      });
    },
    [getData.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload?.errors;
    },
  },
});

export const { clearErrors, clearData } = dataSlice.actions;

export const dataSelector = (state) => state.data;

export default dataSlice.reducer;
