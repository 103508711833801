import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../../../api";

const initialState = {
  usage: {},
  loading: false,
  errors: null,
};

export const getUsage = createAsyncThunk("usage", async (data, thunk) => {
  try {
    let response = await API.orgs.usage.getUsage(data.id, data.from, data.to);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const usageSlice = createSlice({
  name: "usage",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getUsage.pending]: (state) => {
      state.loading = true;
    },
    [getUsage.fulfilled]: (state, action) => {
      state.loading = false;
      state.usage = action.payload;
    },
    [getUsage.rejected]: (state, action) => {
      state.loading = false;
      state.usage = {};
      state.errors = ["Error during data retrieval!"];
    },
  },
});

export const { clearErrors } = usageSlice.actions;

export const usageSelector = (state) => state.usage;

export default usageSlice.reducer;
