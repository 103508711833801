import logo from "../../assets/img/logo_full.svg";
import styles from "./Legal.module.css";

export function Terms() {
  return (
    <>
      <div className={styles.legalContainer}>
        <div className="ta-c mt-10">
          <div>
            <img src={logo} alt="logo" height="100" />
          </div>
        </div>
        <div className="ta-c mt-25 mb-30 fs-6">Terms and Conditions</div>
        <div class="container bg-white b-shadow">
          <p>
            These Terms of Service <b>(“Terms”)</b> govern your use of the
            services provided by [Humanitas Technologies, Inc.] and its
            affiliates and subsidiaries <b>(“we”, “us”, “our”, or “Company”)</b>
            , including without limitation our website, mobile or web
            applications, or other digital products that link to or reference
            these Terms (collectively, the <b> “Services”</b>). These Terms are
            a binding legal agreement between you or the entity you represent
            <b> (“you”) </b> and Company. In these Terms, <b> “you” </b> and
            <b> “your” </b> refer to you, a user of the Services. A
            <b> “user” </b>
            is you or anyone who accesses, browses, or in any way uses the
            Services.
          </p>
          <p>
            <b>
              Please be aware that these Terms include, among other things, a
              binding arbitration provision that requires you to submit to
              binding and final arbitration on an individual basis to resolve
              disputes, rather than jury trials or class actions.
            </b>
            &nbsp; Please see Section 14, below.
          </p>
          <p>
            These Terms refer to our privacy policy&nbsp;
            <a
              href="https://admin.humanitas.ai/privacy"
              style={{ color: "black", textDecoration: "underline" }}
            >
              <b>(“Privacy Policy”)</b>
            </a>
            , hereby incorporated by reference, which also applies to your use
            of the Services and which sets out the terms on which we process any
            personal data we collect from you, or that you provide to us.
          </p>
          <p>
            <b>Please read these Terms carefully, as they may have changed. </b>
            Though your access and use of the Services is governed by the Terms
            effective at the time, please note that we may revise and update
            these Terms from time to time in our discretion. If we make material
            changes to these Terms, we will notify you by email or by posting a
            notice on our website prior to the effective date of the changes.
            These Terms are to ensure that you will use the Services only in the
            ways in which we intend for it to be used.
          </p>
          <b>
            <p>By accessing or using the Services:</p>
            <ul>
              <li>
                You acknowledge that you have read, understood, and accept these
                Terms and any additional documents or policies referred to in or
                incorporated into these Terms, whether you are a visitor to our
                Services or a registered user;
              </li>
              <li>
                If these Terms have materially changed since you last access or
                used the Services, you acknowledge and agree that your continued
                access or use of the Services constitutes your acceptance of the
                changed Terms;
              </li>
              <li>
                You represent and warrant that you are at least eighteen (18)
                years of age and have the right, authority, and capacity to
                enter into these Terms, either on behalf of yourself or the
                entity that you represent; and
              </li>
              <li>
                You consent to receive communications from us electronically,
                and you agree that such electronic communications, notices, and
                postings satisfy any legal requirements that such communications
                be in writing.
              </li>
            </ul>
          </b>
          <br />
          <p>
            <b>1. Accessing the Site.</b>
            <p>
              We grant you permission to access and use the Services subject to
              the restrictions set out in these Terms. It is a condition of your
              use of the Services that the information you provide is correct,
              current, and complete. Your use of the Services is at your own
              risk, including the risk that you may be exposed to content that
              is offensive, indecent, inaccurate, objectionable, or otherwise
              inappropriate. You are responsible for making all arrangements
              necessary for you to have access to the Services. We may close
              your account, suspend your ability to use certain portions of the
              Services, and/or ban you altogether from the Services for any or
              no reason, and without notice or liability of any kind. You are
              responsible for ensuring your access to the Services, and that
              access may involve third-party fees (such as Internet service
              provider or airtime charges) and obtaining and maintaining all
              equipment necessary to access the Services.
            </p>
          </p>
          <br />
          <p>
            <b>2. Account Registration and Account Security.</b>
            <p>
              Some or all of the Services and certain features or
              functionalities, may require you to register an account with us.
              When you do, we may ask you to provide certain registration
              details or other information about yourself. All such information
              is subject to our&nbsp;
              <a
                href="https://humanitas.ai/pp_en.html"
                style={{ color: "black", textDecoration: "underline" }}
              >
                <b>Privacy Policy</b>
              </a>
              . You are responsible for maintaining accurate account information
              at all times, including a valid email address and billing
              information and updating such information as necessary.{" "}
            </p>
            <p>
              Once you have an account, you are responsible for all activities
              that occur in connection with your account. You will treat as
              confidential your account access credentials and will not to
              disclose it to any third-party. You agree to immediately notify us
              if you have any reason to believe that your account credentials
              have been compromised or if there is any unauthorized use of your
              account or password, or any other breach of security. We ask that
              you use particular caution when accessing your profile from a
              public or shared computer, or when using your account in a public
              space, such as a park or cafe or public library, so that others
              are not able to view or record your access credentials or other
              personal information.
            </p>
            <p>
              You may not impersonate someone else to create an account, create
              or use an account for anyone other than yourself, permit anyone
              else to use your account, or provide personal information for
              purposes of account registration other than your own. In order to
              ensure we can protect and properly administer the Services and our
              community of users, we have the right to disable or close any user
              account at any time and for any reason or for no reason.
            </p>
          </p>
          <br />
          <p>
            <b>3. Prohibited Uses.</b>
            <p>
              You may use the Services only for lawful purposes and in
              accordance with these Terms. We are under no obligation to enforce
              the Terms on your behalf against another user. We encourage you to
              let us know if you believe another user has violated the Terms or
              otherwise engaged in prohibited or illegal conduct.
            </p>
            <p>
              You agree not to, and will not assist, encourage, or enable others
              to use the Services:
              <ul>
                <li>
                  For any commercial purpose, except as expressly permitted
                  under these Terms.
                </li>
                <li>
                  To participate in any games for money or other personal
                  property, operate gambling devices, conduct a lottery or pool,
                  sell or purchase lottery tickets, or engage in any other form
                  of gambling.
                </li>
                <li>
                  To violate any applicable national, regional, federal, state,
                  local, or international law or regulation.
                </li>
                <li>
                  To create, send, knowingly receive, display, transmit, upload,
                  download, use, or reuse any material which:
                  <ul>
                    <li>
                      Contain any material which is defamatory, obscene,
                      indecent, abusive, offensive, harassing, violent, hateful,
                      inflammatory, or otherwise objectionable;
                    </li>
                    <li>
                      Infringe any patent, trademark, trade secret, copyright,
                      or other intellectual property rights of any other person;
                    </li>
                    <li>
                      Violate or assists in the violation of legal rights
                      (including the rights of publicity and privacy) of others
                      or contain any material that could give rise to any civil
                      or criminal liability under applicable laws or
                      regulations, or that otherwise may be in conflict with
                      these Terms and/or our Privacy Policy; or
                    </li>
                    <li>Be likely to deceive or confuse any person.</li>
                  </ul>
                </li>
              </ul>
              Additionally, you agree not to, and will not assist, encourage, or
              enable others to:
              <ul>
                <li>
                  Violate these Terms or any Company policies referenced in
                  these Terms.
                </li>
                <li>Reverse engineer any portion of the Services.</li>
                <li>
                  Modify, adapt, appropriate, reproduce, distribute, translate,
                  create derivative works or adaptations of, publicly display,
                  sell, trade, or in any way exploit the Services, except as
                  expressly authorized by Company.
                </li>
                <li>
                  Remove or modify any copyright, trademark, or other
                  proprietary rights notice that appears on any portion of the
                  Services or on any materials printed or copied from the
                  Services.
                </li>
                <li>
                  Record, process, harvest, collect, or mine information about
                  other users.
                </li>
                <li>
                  Access, retrieve, or index any portion of the Services for
                  purposes of constructing or populating a searchable database.
                </li>
                <li>
                  Use any robot, spider, or other automatic device, process, or
                  means to access the Services for any purpose.
                </li>
                <li>
                  Use the Services to transmit any computer viruses, worms,
                  defects, Trojan horses, or other items of a destructive nature
                  (collectively, <b>“Viruses”</b>).
                </li>
                <li>
                  Use the Services to violate the security of any computer
                  network, crack passwords or security encryption codes.
                </li>
                <li>
                  Remove, circumvent, disable, damage, or otherwise interfere
                  with any security-related features or other restrictions of
                  the Services.
                </li>
                <li>
                  Attempt to gain unauthorized access to, interfere with,
                  damage, or disrupt any parts of the Services, the server on
                  which the Services is stored, or any server, computer, or
                  database connected to the Services.
                </li>
                <li>
                  Attack the Services via a denial-of-service attack or a
                  distributed denial-of-service attack.
                </li>
                <li>
                  Otherwise attempt to interfere with the proper working of the
                  Services.
                </li>
              </ul>
              The restrictions above only apply to the extent permissible under
              applicable law. Nevertheless, you agree not to act contrary to
              them (even if permissible under applicable law) without providing
              thirty (30) days’ prior written notice to us at
              [hello@humanitas.ai], together with any information that we may
              reasonably require to give us an opportunity to provide
              alternative remedies or otherwise accommodate you at our sole
              discretion.
            </p>
          </p>
          <br />
          <p>
            <b>
              4. Ownership of Company Content; Third Party Content; No
              Professional Advice.
            </b>
            <p>
              We own the Services and all of our trademarks, logos, branding,
              and any other Content that we create in connection with the
              Services (“Company Content”), including proprietary rights of
              every kind and nature however denominated throughout the world,
              registered or unregistered, associated with such Company Content
              and the Services (collectively, <b>“Company IP”</b>). Except as
              expressly and unambiguously provided herein, we do not grant you
              any express or implied rights, and all rights in and to the
              Company IP are retained by us.
            </p>
            <p>
              <b>
                We are not responsible or liable to any third-party for the
                content or accuracy of any materials posted by you or any other
                user of the Services.
              </b>
              &nbsp; You understand that when using the Services, you will be
              exposed to text, images, photos, audio, video, location data, and
              all other forms of data or communication (“Content”) from a
              variety of sources, and that Company is not responsible for the
              accuracy, usefulness, safety, or intellectual property rights of
              or relating to such Content. We do not endorse any Content made
              available through the Services by any user or other licensor, or
              any opinion, recommendation, or advice expressed therein, and we
              expressly disclaim any and all liability in connection with such
              Content.
            </p>
            <p>
              <b>
                All information and content provided by Company relating to the
                Services is for informational purposes only and Company does not
                guarantee the accuracy, completeness, timeliness or reliability
                of any such information or content. No content is intended to
                provide financial, legal, tax or other professional advice.
                Before making any decisions regarding any Campaigns, Charities,
                Donations, Donors, or any information or content relating to the
                Services, you should consult your financial, legal, tax or other
                professional advisor as appropriate.
              </b>
            </p>
          </p>
          <br />
          <p>
            <b>5. Responsibility for Your Content.</b>
            <p>
              You understand and acknowledge that you alone are responsible for
              Content that you submit or transmit to, through, or in connection
              with the Services or that you publicly display or displayed in
              your account profile (collectively, <b>“Your Content”</b>), and
              you, not Company, assume all risks associated with Your Content,
              including anyone’s reliance on its quality, accuracy, reliability,
              appropriateness, or any disclosure by you of information in Your
              Content that makes you or anyone else personally identifiable. You
              represent that you own or have the necessary rights, consents, and
              permissions to use and authorize the use of Your Content as
              described herein. You may not imply that Your Content is in any
              way sponsored or endorsed by Company. You represent and warrant
              that Your Content does not violate these Terms.
            </p>
            <p>
              <u>
                Copyright Infringement and Digital Millennium Copyright Act:
              </u>
            </p>
            <p>
              We respect the intellectual property rights of others, and we ask
              our users to do the same. In accordance with the Digital
              Millennium Copyright Act (DMCA) and other applicable law, in
              appropriate circumstances and in our sole discretion, we may
              terminate the rights of any user to use the Services (or any part
              thereof) who infringes the intellectual property rights of others.
              If you believe that your work has been copied in a way that
              constitutes copyright infringement, or if you are aware of someone
              so infringing on your rights, please provide the following
              information to the “Copyright Agent”:
              <ul>
                <li>
                  An electronic or physical signature of the person authorized
                  to act on behalf of the owner of the copyright interest.
                </li>
                <li>
                  A description of the copyrighted work that you claim has been
                  infringed upon.
                </li>
                <li>
                  A description of where the material that you claim is
                  infringing is located on the Services.
                </li>
                <li>Your address, telephone number, and email address.</li>
                <li>
                  A statement that you have a good faith belief that the
                  disputed use is not authorized by the copyright owner, its
                  agent, and/or the law.
                </li>
                <li>
                  A statement by you, made under penalty of perjury, that the
                  above information in your notice is accurate, and that you are
                  the copyright owner or authorized to act on the copyright
                  owner’s behalf.
                </li>
              </ul>
              All notices of copyright infringement claims should go to our
              Copyright Agent at [hello@humanitas.ai].
            </p>
            <p>
              <u>Our Right to Use Your Content:</u>
            </p>
            <p>
              You hereby irrevocably grant us world-wide, perpetual,
              non-exclusive, royalty-free, assignable, sublicensable,
              transferable rights to use Your Content for any purpose. Pursuant
              to this grant, you agree that we may use Your Content in a number
              of different ways, including by publicly displaying it,
              reformatting it, incorporating it into advertisements and other
              works, creating derivative works from it, promoting it, and
              distributing it. Please note that you also irrevocably grant the
              users of the Services the right to access Your Content in
              connection with their use of the Services. Finally, you
              irrevocably waive, and cause to be waived, against Company and its
              users any claims and assertions of moral right or attribution with
              respect to Your Content. By “use” we mean use, make, have made,
              sell, offer for sale, import, practice, copy, publicly perform and
              display, reproduce, perform, distribute, modify, translate,
              remove, analyze, commercialize, and prepare derivative works of
              Your Content. We are not and shall not be under any obligation,
              except as otherwise expressly set forth in these Terms or our
              other policies, (1) to maintain Your Content in confidence; (2) to
              pay you any compensation for Your Content; (3) to credit or
              acknowledge you for Your Content; (4) to respond to Your Content;
              or (5) to exercise any of the rights granted herein with respect
              to Your Content.
            </p>
          </p>
          <br />
          <p>
            <b>6. Donors.</b>
            <p>
              “Donors” means users of the Services who make monetary donations
              (“Donations”) to Charities through the Services. The following
              terms shall apply to Donors.
            </p>
            <ul>
              <li>
                All Donations are at your own risk. When you make a Donation
                through either of the Platforms, it is your responsibility to
                understand how your money will be used. Company is not
                responsible for any offers, promises, rewards or promotions made
                or offered by Charities or other users. We may charge you a
                platform fee and any associated transaction fees, including
                payment processing fees, for each Donation you make on the
                Services. We do not and cannot verify the information that
                Charities supply, nor do we represent or guarantee that the
                Donations will be used in accordance with any fundraising
                purpose prescribed by a Charity or user or in accordance with
                applicable laws.&nbsp;
                <b>
                  While we may provide recommended causes or Charities based on
                  your stated preferences, we do not endorse any Charity, user,
                  or cause and we make no guarantee, express or implied, that
                  any information provided through the Services is accurate.
                </b>
              </li>
              <li>
                You acknowledge and agree that we may route or initially place
                your Donation with our 501(c)(3) fiscal partner, Philanthropic
                Ventures Foundation, EIN: 94-3136771 (“Fiscal Partner”) prior to
                disbursing your Donation to the receiving Charity or cause.
              </li>
              <li>
                Donors are not permitted to impose restrictions on the use of
                Donations by a Charity. To the extent that a Donation is made in
                response to an appeal or recommendation for a particular program
                of a Charity, or to the extent that a Donor purports to direct
                the use of Donations by a Charity, any such directions shall
                constitute non-binding recommendations only and the Charity
                shall have full discretion to determine how all Donations will
                be used.
              </li>
              <li>
                Company makes no representation as to whether all or any portion
                of your Donations, including, if any, payment processor fees,
                are tax deductible or eligible for tax credits. Company will
                have no liability for any claim by any federal, state,
                provincial, territorial, local or any other tax authority with
                respect to the characterization on any applicable tax return of
                any Donation by you, any user or any Charity. You should consult
                your tax advisor as to the amount of your Donation that is tax
                deductible or eligible for tax recognition, having regard to
                (among other things) the tax status of the recipient of any
                Donation in any relevant jurisdiction, and particularly if you
                are awarded a gift or prize in connection with your Donation.
              </li>
              <li>
                Donors shall provide Company and/or or our Fiscal Partner, with
                such information as is required to enable the issuing of an
                official Donation receipt. Donor acknowledges and agrees that,
                in accordance with the Privacy Policy, certain of Donor’s
                personal information will be shared with the Charity to which
                such Donor makes a donation (including without limitation as
                part of a Donor List, as set forth above) and may be used by
                such Charity in accordance with the Charity’s Privacy Policy.
                Company is not responsible, and shall not be liable, for any
                Charity’s use of any Donor information.
              </li>
              <li>
                You, as a Donor, represent and warrant to Company that such
                information is true and that you are authorized to use the
                applicable Payment Instrument. You agree that a certain minimum
                Donation amount may apply, and that all Donations are final and
                will not be refunded unless Company, in its sole discretion,
                agrees to issue a refund. If Company uses third-party payment
                processing partners to bill you through your payment instrument
                and billing account for any Donations made, and Donors
                acknowledge that by contributing a Donation, the Donor agrees to
                the processing, use, transfer or disclosure of data by our
                payment processors pursuant to our{" "}
                <a
                  href="https://humanitas.ai/pp_en.html"
                  style={{ color: "black", textDecoration: "underline" }}
                >
                  <b>Privacy Policy</b>
                </a>
                &nbsp; and any and all applicable terms set forth by our payment
                partners, in addition to these Terms.
              </li>
              <li>
                Donors may have the option to contribute recurring period
                Donations (your agreement to make the Donations on a recurring
                basis, a “Donation Subscription” and each individual Donation
                made in connection with a Donation Subscription, a “Donation
                Installment”), and in electing to contribute on a recurring
                basis, you, as a Donor hereby acknowledge that Donation
                Subscriptions automatically renew and have a recurring payment
                feature, and that unless and until you opt out of the
                auto-renewal of the Donation Subscription, which can be done
                through the Services, any Donation Subscriptions you have signed
                up for will be automatically extended for successive renewal
                periods of the same duration as the initial term originally
                selected. In connection with each of your Donation
                Subscriptions, you (i) hereby authorize Company to bill your
                payment instrument in the amount of the applicable Donation
                Installments in advance on a periodic basis until you terminate
                such periodic payments by opting out of the Donation
                Subscription, (ii) accept responsibility for payment of all
                Donation Installments occurring prior to opt out and (iii) agree
                to promptly update your billing account with any changes (for
                example, any changes related to your payment instrument, such as
                a change in your billing address or credit card expiration
                date). Changes to or termination of Donation Subscriptions or
                Donation Installments will apply only to Donation Installments
                that take place after Company receives notice of such change or
                termination. Company does not provide refunds of any amounts
                received in connection with previously made Donation
                Installments. Additionally, by enrolling in any Donation
                Subscriptions, you acknowledge and agree for any and all such
                Donation Subscriptions, that (a) the ongoing maintenance and
                operation of Donation Subscriptions and each Donation
                Installment are the sole responsibility of, and subject to the
                sole discretion of, the individual or entity responsible for
                managing and receiving the Donation Subscription (e.g., the
                applicable Charity), (b) individual Donation Installments may
                not be tax deductible, even if previous Donation Installments
                for the same Donation Subscription were, and the amount of each
                Donation Installment that is tax deductible may vary, (c) one or
                more specific Donation Installment may not be provided to or
                received by the applicable cause or Charity if such cause or
                Charity becomes unavailable, unable to accept Donations, or
                chooses to stop receiving Donations, which may occur for various
                reasons, such as if the cause or Charity becomes subject to an
                investigation or is suspended or removed from the Services by
                Company or (d) the amounts actually received by the applicable
                Charity or cause may differ from one Donation Installment to the
                next (for example, if the payment processor fees associated with
                the Donation Installment change).
              </li>
              <li>
                Donor Rewards. We may offer promotional credits that, when
                validly applied, allow a Donor to utilize the amount of such
                credit as a Donation (“Donor Reward”). The redeemable Donation
                value of a Donor Reward shall not at any time exceed the credit
                amount specified by Company. We may cease offering Donor Rewards
                at any time and for any reason. Furthermore:
                <ul>
                  <li>
                    Donor Rewards have no cash value and may only be applied
                    once.
                  </li>
                  <li>Donor Rewards may be subject to an expiration date.</li>
                  <li>
                    Donor Rewards may be subject to conditions specified by
                    Company, which may include restrictions on geography,
                    Charity, or otherwise.
                  </li>
                  <li>
                    Donor Rewards are non-transferable and may not be
                    transferred to another account, resold to a third party, or
                    used for any commercial purposes.
                  </li>
                  <li>
                    Each Donor Reward may not be combined with other promotions.
                  </li>
                  <li>
                    Virtual Events. We may also, in our sole discretion, offer
                    to certain users exclusive content or limited availability
                    events as part of the Services, such as live video
                    conferences with well known individuals, or video tours of
                    select Charities (“Virtual Events”). We may choose to record
                    some or parts of these Virtual Events (each a “Recording”).
                    By participating or attending a Virtual Event, you hereby
                    consent to the (a) monitoring or recording of such Virtual
                    Event, (b) our copying, using, and disclosing of any
                    Recording for any business purpose, (c) such Recording being
                    made available to other participants. You further waive any
                    rights of publicity, privacy, or other rights under
                    applicable law and release and discharge any claims relating
                    to the Virtual Event or our use of Recordings. Your
                    participation in any Virtual Event is expressly conditioned
                    upon you fully providing responses and information that we
                    may request. We may choose to not include you, or revoke
                    your access to, a Virtual Event at any time for any reason.
                  </li>
                </ul>
              </li>
            </ul>
          </p>
          <br />
          <p>
            <b>7. Charities.</b>
            <p>
              “Charity” means a 501(c)(3), (c)(4), or other non-profit
              organization established as such under the applicable laws of
              incorporation.
            </p>
            <p>
              <ul>
                <li>
                  Company merely provides the technology to allow fundraisers to
                  connect with Donors. The Services are administrative platforms
                  only. Company is not a broker, agent, financial institution,
                  creditor or 501(c)(3) nonprofit corporation. The existence of
                  the Services is not a solicitation of donations by Company,
                  and Company does not engage in any solicitation activities, or
                  consult on the solicitation of contributions from the public,
                  on behalf of any individual, entity, or organization.
                </li>
                <li>
                  By using the Services, you understand and agree that Company
                  shall not be responsible for the use of your donations or the
                  amount of funds raised for the Campaign or event.&nbsp;
                  <b>
                    We do not guarantee that a Charity will obtain a certain
                    amount of Donations or any Donations at all. While we may
                    provide recommended causes or Charities based on Donors’
                    stated preferences, we do not endorse any Charity, user, or
                    cause and we make no guarantee, express or implied, that any
                    information provided through the Services is accurate.
                  </b>
                  &nbsp; It is your responsibility to determine what, if any,
                  taxes apply to the Donations you receive through your use of
                  the Services. It is solely your responsibility to assess,
                  collect, report or remit the correct tax, if any, to the
                  appropriate tax authority.
                </li>
                <li>
                  You represent and warrant that: (a) all information you
                  provide in connection with the Services is accurate, complete,
                  and not likely to deceive reasonable users; (b) you will not
                  infringe the rights of others; (c) you will comply with all
                  relevant and applicable law and financial reporting
                  obligations, including but not limited to laws and regulations
                  relating to registration, tax reporting, political
                  contributions, and asset disclosures for your project; (d) to
                  the extent you share with us any personal data of any third
                  party for any purpose, including the names, email addresses
                  and phone numbers of your personal contacts, you have the
                  authority (including any necessary consents), as required
                  under applicable law, to provide us with such personal data
                  and allow us to use such personal data for the purposes for
                  which you shared it with us; (e) you are raising funds for a
                  Charity or charitable purpose, with a cause or activity that
                  is legal under all applicable federal, state, provincial,
                  territorial and local laws and regulations; (f) all donated
                  funds will be used solely for the purpose you have stated on
                  and in connection with the Services, and under no
                  circumstances may you use the funds for any other purpose; (g)
                  you have and will maintain tax-exempt status under applicable
                  law (example, the Internal Revenue Code in the United States
                  or the Income Tax Act in Canada; and (h) if you are a Charity
                  in the United States, you are registered with GuideStar or the
                  IRS tax exempt organization database, or, if you are a Charity
                  in Canada, you are listed in the Canada Revenue Agency's
                  database of registered charities
                </li>
                <li>
                  If you access the Services as or on behalf of a Charity, you
                  further represent and warrant that you are a representative of
                  the Charity and are authorized to raise funds or bind the
                  Charity to these Terms.
                </li>
                <li>
                  You hereby further agree to:
                  <ul>
                    <li>
                      provide all information on a regular basis as requested by
                      Company, including without limitation information about
                      your operations, impact statement, and any other
                      information necessary for Company to determine the
                      eligibility of your Charity for the Services;
                    </li>
                    <li>
                      not to make or accept any Donations that you know or
                      suspect to be erroneous, suspicious or fraudulent;
                    </li>
                    <li>
                      not to use the Services in or for the benefit of a
                      country, organization, entity, or person embargoed or
                      blocked by any government, including those on sanctions
                      lists identified by the United States Office of Foreign
                      Asset Control (OFAC);
                    </li>
                    <li>
                      to maintain reasonable and standard security measures to
                      protect any information transmitted and received through
                      the Services, including without limitation by adhering to
                      any security procedures and controls required by Company
                      from time to time;
                    </li>
                    <li>
                      to maintain a copy of all electronic and other records
                      related to Donations as necessary for Company to verify
                      compliance with these Terms and make such records
                      available to Company upon our request. For clarity, the
                      foregoing does not affect or limit your obligations to
                      maintain documentation as required by applicable laws,
                      rules, regulations, or governmental authority; and
                    </li>
                    <li>
                      at Company’s request, including without limitation in case
                      of investigations by Company, a payment processing
                      partner, or a regulatory or governmental authority, to
                      fully cooperate in the auditing of such records,
                      investigation of the relevant circumstances and remedy of
                      any uncovered violation or wrongdoing.
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
          </p>
          <br />
          <p>
            <b>8. Links to other Sites.</b>
            <p>
              If there are other websites and resources linked to on the
              Services, these links are provided only for the convenience of our
              users. We have no control over the contents of those websites or
              resources, and therefore cannot accept responsibility for them or
              for any loss or damage that may arise from your use of them. If
              you decide to access any of the third-party websites linked to the
              Services, you do so entirely at your own risk and subject to the
              Terms and conditions of use for such websites.
            </p>
          </p>
          <br />
          <p>
            <b>9. No Guarantee of Service.</b>
            <p>
              Although we hope to make the Services available at all times in
              the future, there may be times when we need to disable the
              Services either temporarily or permanently. The Services may be
              modified, updated, interrupted, suspended, or discontinued at any
              time without notice or liability. Keep this in mind as Company
              will not be liable if all or any part of the Services is
              unavailable at any time, for any period of time. Also, from time
              to time, we may restrict access to some parts of the Services, or
              the entire Services, to users, including registered users. We
              cannot and do not make any representations or warranties with
              respect to the devices you use to access or use the Services,
              including with respect to device compatibility.
            </p>
          </p>
          <br />
          <p>
            <b>10. Disclaimer of Warranties.</b>
            <p>
              COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, EITHER
              EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT
              LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
              AND FITNESS FOR PARTICULAR PURPOSE. THE SERVICES ARE PROVIDED ON
              AN “AS IS” AND “AS AVAILABLE” BASIS. YOU FURTHER WAIVE AND HOLD
              HARMLESS COMPANY FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN
              BY COMPANY DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM
              ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER
              COMPANY OR LAW ENFORCEMENT AUTHORITIES.
            </p>
            <p>
              IF YOU ARE A RESIDENT OF A JURISDICTION THAT REQUIRES A SPECIFIC
              STATEMENT REGARDING RELEASE, THEN THE FOLLOWING APPLIES. FOR
              EXAMPLE, (1) CALIFORNIA RESIDENTS MUST, AS A CONDITION OF THIS
              AGREEMENT, WAIVE THE APPLICABILITY OF CALIFORNIA CIVIL CODE
              SECTION 1542, WHICH STATES, "A GENERAL RELEASE DOES NOT EXTEND TO
              CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR
              SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE
              RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY
              AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY"
              AND (2) NEW JERSEY RESIDENTS MUST, AS A CONDITION OF THIS
              AGREEMENT, WAIVE THE APPLICABILITY OF THE TRUTH IN CONSUMER
              CONTRACT, WARRANTY, AND NOTICE ACT (X) SECTION 15, WHICH STATES,
              AMONG OTHER THINGS, THAT “NO SELLERS…SHALL IN THE COURSE OF HIS
              BUSINESS OFFER TO ANY CONSUMER OR PROSPECTIVE CONSUMER OR ENTER
              INTO ANY WRITTEN CONSUMER CONTRACT OR GIVE OR DISPLAY ANY WRITTEN
              CONSUMER WARRANTY, NOTICE OR SIGN…WHICH INCLUDES ANY PROVISION
              THAT VIOLATES ANY CLEARLY ESTABLISHED LEGAL RIGHT OF A CONSUMER OR
              RESPONSIBILITY OF A SELLER…” AND (X) SECTION 16, WHICH STATES,
              AMONG OTHER THINGS, THAT “…NO CONSUMER CONTRACT, NOTICE OR SIGN
              SHALL STATE THAT ANY OF ITS PROVISIONS IS OR MAY BE VOID,
              UNENFORCEABLE OR INAPPLICABLE IN SOME JURISDICTIONS WITHOUT
              SPECIFYING WHICH PROVISIONS ARE OR ARE NOT VOID, UNENFORCEABLE OR
              INAPPLICABLE WITHIN THE STATE OF NEW JERSEY…”. YOU HEREBY WAIVE,
              AS APPLICABLE, THESE SECTIONS OF THE CALIFORNIA CIVIL CODE AND NEW
              JERSEY TRUTH IN CONSUMER CONTRACT, WARRANTY, AND NOTICE ACT. YOU
              HEREBY WAIVE ANY SIMILAR PROVISION IN LAW, REGULATION, OR CODE
              THAT HAS THE SAME INTENT OR EFFECT AS THE AFOREMENTIONED
              PROVISIONS.
            </p>
          </p>
          <br />
          <p>
            <b>11. Limit of Liability.</b>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COMPANY
              BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR
              CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE
              SERVICES, WHETHER OR NOT COMPANY HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES OR FROM ANY COMMUNICATIONS,
              INTERACTIONS OR MEETINGS WITH OTHER USERS OF THE SITE OR SERVICES,
              ON ANY THEORY OF LIABILITY (INCLUDING CONTRACT, TORT INCLUDING
              NEGLIGENCE, OR OTHERWISE) ARISING OUT OF, IN CONNECTION WITH, OR
              RESULTING FROM THE SERVICES. COMPANY’S AGGREGATE LIABILITY UNDER
              THESE TERMS SHALL BE LIMITED TO FEES ACTUALLY PAID BY YOU IN
              CONNECTION WITH THE SERVICES IN THE SIX (6) MONTHS PRECEDING THE
              EVENT GIVING RISE TO YOUR CLAIM OR, IF NO FEES APPLY, ONE HUNDRED
              U.S. DOLLARS ($100). THE PROVISIONS OF THIS SECTION ALLOCATE THE
              RISKS UNDER THESE TERMS BETWEEN THE PARTIES, AND THE PARTIES HAVE
              RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO ENTER INTO
              THESE TERMS.
            </p>
          </p>
          <br />
          <p>
            <b>12. Indemnification.</b>
            <p>
              You shall indemnify and hold harmless Company, its affiliates and
              licensors and their respective officers, directors, employees,
              contractors, agents, licensors and suppliers from and against any
              claims, liabilities, damages, judgments, awards, losses, costs,
              expenses or fees (including reasonable attorneys’ fees) resulting
              from your violation of these Terms or your use of the Services.
            </p>
          </p>
          <br />
          <p>
            <b>13. Governing Law.</b>
            <p>
              This Agreement shall be governed by the laws of the State of
              California, without respect to its conflict of laws principles.
              You agree to submit to the personal jurisdiction of the federal
              and state courts located in the San Francisco County, California.
            </p>
          </p>
          <br />
          <p>
            <b>14. Arbitration and Class Action Waiver.</b>
            <ul>
              <li>
                <b>Arbitration.</b> READ THIS SECTION CAREFULLY BECAUSE IT
                REQUIRES THE PARTIES TO ARBITRATE THEIR DISPUTES AND LIMITS THE
                MANNER IN WHICH YOU CAN SEEK RELIEF FROM COMPANY. For any
                dispute with Company, you agree to first contact us at
                [hello@humanitas.ai] and attempt to resolve the dispute with us
                informally. In the unlikely event that Company has not been able
                to resolve a dispute it has with you after sixty (60) days, we
                each agree to resolve any claim, dispute, or controversy
                (excluding any claims for injunctive or other equitable relief
                as provided below) arising out of or in connection with or
                relating to this Agreement, or the breach or alleged breach
                thereof (collectively, <b>“Claims”</b>), by binding arbitration
                by JAMS, under the Optional Expedited Arbitration Procedures
                then in effect for JAMS, except as provided herein. JAMS may be
                contacted at www.jamsadr.com. The arbitration will be conducted
                in the San Francisco County, California, unless you and Company
                agree otherwise. If you are using the Services for commercial
                purposes, each party will be responsible for paying any JAMS
                filing, administrative and arbitrator fees in accordance with
                JAMS rules, and the award rendered by the arbitrator shall
                include costs of arbitration, reasonable attorneys’ fees and
                reasonable costs for expert and other witnesses. If you are an
                individual using the Services for non-commercial purposes: (i)
                JAMS may require you to pay a fee for the initiation of your
                case, unless you apply for and successfully obtain a fee waiver
                from JAMS; (ii) the award rendered by the arbitrator may include
                your costs of arbitration, your reasonable attorney’s fees, and
                your reasonable costs for expert and other witnesses; and (iii)
                you may sue in a small claims court of competent jurisdiction
                without first engaging in arbitration, but this does not absolve
                you of your commitment to engage in the informal dispute
                resolution process. Any judgment on the award rendered by the
                arbitrator may be entered in any court of competent
                jurisdiction. Nothing in this Section shall be deemed as
                preventing Company from seeking injunctive or other equitable
                relief from the courts as necessary to prevent the actual or
                threatened infringement, misappropriation, or violation of our
                data security, intellectual property rights or other proprietary
                rights.
              </li>
              <li>
                <b>Class Action/Jury Trial Waiver.</b> WITH RESPECT TO ALL
                PERSONS AND ENTITIES, REGARDLESS OF WHETHER THEY HAVE OBTAINED
                OR USED THE SERVICES FOR PERSONAL, COMMERCIAL OR OTHER PURPOSES,
                ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY,
                AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
                ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR
                OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS
                ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY
                NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT,
                BY ENTERING INTO THIS AGREEMENT, YOU AND COMPANY ARE EACH
                WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A
                CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL
                ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <b>15. General Terms.</b>
            <p>
              These Terms constitute the sole and entire agreement between you
              and Company with respect to the Services and supersede all prior
              and contemporaneous understandings, agreements, representations
              and warranties, both written and oral, with respect to the
              Services. No waiver of these Terms by Company shall be deemed a
              further or continuing waiver of such term or condition or any
              other term or condition, and any failure of Company to assert a
              right or provision under these Terms shall not constitute a waiver
              of such right or provision. If any provision of these Terms is
              held by a court of competent jurisdiction to be invalid, illegal,
              or unenforceable for any reason, such provision shall be modified
              to reflect the parties’ intention or eliminated to the minimum
              extent such that the remaining provisions of the Terms will
              continue in full force and effect. The Terms, and any rights or
              obligations hereunder, are not assignable, transferable, or
              sublicensable by you except with Company’s prior written consent,
              but may be assigned or transferred by us without restriction. Any
              attempted assignment by you shall violate these Terms and be void.
              The section titles in the Terms are for convenience only and have
              no legal or contractual effect.
            </p>
          </p>
          <br />
          <p>
            <b>16. Contact.</b>
            <p>
              All feedback, comments, requests for technical support, and other
              communications relating to the Services should be directed to:
              [hello@humanitas.ai]. By sending us any ideas, suggestions,
              documents or proposals <b>(“Feedback”)</b>, you agree that (i)
              your Feedback does not contain the confidential or proprietary
              information of third-parties, (ii) we are under no obligation of
              confidentiality, express or implied, with respect to the Feedback,
              (iii) we may have something similar to the Feedback already under
              consideration or in development that is owned by us, and (iv) you
              hereby assign us all right, title, and interest in and to such
              Feedback, and, to the extent applicable, you irrevocably waive,
              and cause to be waived, against Company and our users any claims
              and assertions of any moral rights contained in such Feedback.
            </p>
          </p>
          <div className={styles.updated}>Last Updated: 8th April 2022</div>
        </div>
      </div>
    </>
  );
}
