import { PhoneOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Radio, Row, Space, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Loader } from "../../components/loader/Loader";
import { authSelector } from "../auth/authSlice";
import { getLatestSummary } from "../users/usersSlice";
import styles from "./Chat.module.css";

const streamUrl =
  process.env.NODE_ENV === "production"
    ? "wss://casey.humanitas.ai/assistant"
    : "ws://localhost:8080/assistant";

export function Chat() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector(authSelector);
  const [currentModel, setCurrentModel] = useState();
  const [ws, setWs] = useState(null);
  const [summary, setSummary] = useState("");
  const [firstMessage, setFirstMessage] = useState(false);
  const [messages, setMessages] = useState([]);
  const [next, setNext] = useState();
  const [query, setQuery] = useState("");
  const [stream, setStream] = useState("");
  const [streamStopped, setStreamStopped] = useState(false);
  const chatEndRef = useRef(null);

  const resetChat = () => {
    setCurrentModel(null);
    setMessages([]);
  };

  useEffect(() => {
    dispatch(getLatestSummary({})).then((res) => {
      if (res.type === "users/latestSummary/fulfilled") {
        setSummary(res.payload.summary);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    resetChat();
  }, []);

  useEffect(() => {
    return () => {
      closeWebSocket();
    };
  }, [ws]);

  useEffect(() => {
    if (currentModel) {
      connectWebSocket(currentModel);
    }

    return () => {
      closeWebSocket();
    };
  }, [currentModel]);

  useEffect(() => {
    if (streamStopped) {
      setMessages((state) => [...state, { from: "system", text: stream }]);
      setStream("");
      setStreamStopped(false);
    }
  }, [streamStopped, stream]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages.length]);

  const sendQuery = () => {
    if (query.length === 0) return;
    setQuery("");
    setMessages((state) => [...state, { from: "user", text: query }]);
    setNext("system");
    ws.send(JSON.stringify({ cmd: "query", val: query }));
  };

  const stopStream = () => {
    ws.send(JSON.stringify({ cmd: "stop" }));
  };

  const connectWebSocket = (model) => {
    console.log("Connecting Socket");

    const socket = new WebSocket(
      `${streamUrl}?uid=${localStorage.getItem(
        "uid"
      )}&token=${localStorage.getItem(
        "access-token"
      )}&client=${localStorage.getItem(
        "client"
      )}&control_id=${uuidv4()}&model=${model}`
    );

    socket.onopen = () => {
      console.log("WebSocket Connected");
    };

    socket.onmessage = (e) => {
      setFirstMessage(true);
      const res = JSON.parse(e.data);
      setNext();
      if (res.cmd === "stream") {
        setStream((state) => state + res.val);
      } else if (res.cmd === "stop") {
        setStreamStopped(true);
      } else if (res.cmd === "error") {
        message.error(res.val);
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    socket.onclose = (e) => {
      if (e.code === 4401) {
        message.error("Invalid User. Contact admin.");
      } else if (e.code === 4402) {
        message.error(
          "Another active chat detected from user. Close that chat before starting a new chat."
        );
      }
      console.log("WebSocket Disconnected:", e.reason, "Code:", e.code);
      resetChat();
      // setTimeout(connectWebSocket, 3000); // Reconnect after 3 seconds
    };

    setWs(socket);
  };

  const closeWebSocket = () => {
    if (ws) {
      ws.close();
      setCurrentModel(null);
    }
  };

  return (
    <>
      <div className="container">
        <div className="mb-15">
          <Row justify="space-between" wrap>
            <Col>
              <span className="fs-5 fw-300">Casey Chat</span>
            </Col>
            <Col className="ta-r fw-500 fs-3">{currentModel}</Col>
          </Row>
        </div>
        {ws?.readyState === WebSocket.OPEN ? (
          <>
            <div className="container bg-white b-shadow">
              <Row className={styles.chatBox} gutter={24}>
                <Col span={17} style={{ overflowY: "auto" }}>
                  <div className={styles.chatArea}>
                    {messages.map((m, i) =>
                      m.from === "system" ? (
                        <div className={styles.system} key={i}>
                          <div>
                            <Space size={24} align="top">
                              <div className="fs-3">
                                <SmileOutlined />
                              </div>
                              {m.text}
                            </Space>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.user} key={i}>
                          <div>
                            <Space size={24} align="top">
                              <div className="fs-3">
                                <PhoneOutlined />
                              </div>
                              {m.text}
                            </Space>
                          </div>
                        </div>
                      )
                    )}

                    {!firstMessage && (
                      <div className={styles.system}>
                        <div>
                          <Space size={24} align="top">
                            <div className="fs-3">
                              <SmileOutlined />
                            </div>
                            <Loader size={36} />
                          </Space>
                        </div>
                      </div>
                    )}

                    {stream.length > 0 && (
                      <div className={styles.system}>
                        <div>
                          <Space size={24} align="top">
                            <div className="fs-3">
                              <SmileOutlined />
                            </div>
                            {stream}
                          </Space>
                        </div>
                      </div>
                    )}

                    {next === "user" && (
                      <div className={styles.user}>
                        <div>
                          <Space size={24} align="top">
                            <div className="fs-3">
                              <PhoneOutlined />
                            </div>
                            <Loader size={36} />
                          </Space>
                        </div>
                      </div>
                    )}
                    {next === "system" && (
                      <div className={styles.system}>
                        <div>
                          <Space size={24} align="top">
                            <div className="fs-3">
                              <SmileOutlined />
                            </div>
                            <Loader size={36} />
                          </Space>
                        </div>
                      </div>
                    )}
                    <div ref={chatEndRef}></div>
                  </div>
                </Col>
                <Col span={7}>
                  <div className="info">
                    <div>
                      <Input.TextArea
                        value={query}
                        rows={5}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                      <div style={{ paddingTop: "10px" }}>
                        {stream.length === 0 ? (
                          <Button type="primary" onClick={() => sendQuery()}>
                            Send
                          </Button>
                        ) : (
                          <Button onClick={() => stopStream()}>Stop</Button>
                        )}
                      </div>
                    </div>
                    <div className="mt-30">
                      User:{" "}
                      <Link to={`/users/${currentUser?.id}`}>
                        {currentUser?.f_name} {currentUser?.l_name}
                      </Link>
                    </div>
                    {summary && (
                      <div className={`mt-10 ${styles.summaryBox}`}>
                        <div className="fw-500">Latest Summary</div>
                        <div className="mt-10">{summary}</div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </>
        ) : ws?.readyState === WebSocket.CONNECTING ? (
          <div className="mt-15">
            <div className={`${styles.modelChoose} bg-white b-shadow`}>
              <div className="mt-30 mb-30">
                <Loader size={50} />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="mt-15">
              <div className={`${styles.modelChoose} bg-white b-shadow`}>
                <div className="fw-500">
                  Choose a model for this conversation
                </div>
                <Form
                  className="mt-20"
                  onFinish={(values) => {
                    // setModel(values?.model);
                    setCurrentModel(values?.model);
                    navigate({
                      search: `?live=true`,
                    });
                  }}
                  initialValues={{ model: "gpt-4o-mini" }}
                >
                  <Form.Item name="model">
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value="gpt-3.5-turbo-0125">
                          gpt-3.5-turbo-0125
                        </Radio>
                        <Radio value="gpt-4o">gpt-4o</Radio>
                        <Radio value="gpt-4o-mini">gpt-4o-mini</Radio>
                        <Radio value="claude-3-5-sonnet-20240620">
                          claude-3-5-sonnet-20240620
                        </Radio>
                        <Radio value="vera">vera + gpt-4o-mini</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="mt-25"
                    size="small"
                  >
                    Choose
                  </Button>
                </Form>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
