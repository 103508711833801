import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateBulkService } from "./servicesSlice";

const BatchSize = 10;
const UpdateFields = ["id", "time", "amount", "cost", "trust"];

export function BulkUpdate(props) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [processed, setProcessed] = useState(false);

  useEffect(() => {
    if (props.data?.length > 0) {
      setData(
        props.data.slice(1).map((d, i) => {
          let formatted = { key: i };
          d.forEach((f, j) => {
            formatted[UpdateFields[j]] = f;
          });
          formatted.status = 0;
          formatted.error = null;
          return formatted;
        })
      );
    }
  }, [props.data]);

  const getColumns = () => {
    return ["status"].concat(UpdateFields).map((f) => {
      let obj = {
        title: f,
        dataIndex: f,
        key: f,
      };
      if (f === "status") {
        obj.render = (_, d) => {
          switch (d.status) {
            case 1:
              return <SyncOutlined spin />;
            case 2:
              return <CheckCircleOutlined style={{ color: "#49aa19" }} />;
            case 3:
              return (
                <Tooltip title={d.error}>
                  <CloseCircleOutlined style={{ color: "#dc4446" }} />
                </Tooltip>
              );
            default:
          }
        };
      }
      return obj;
    });
  };

  const updateStatus = (id, status) => {
    setData(
      data.map((d) => {
        if (d.id === id) {
          d.status = status;
        }
        return d;
      })
    );
  };

  const updateError = (id, error) => {
    setData(
      data.map((d) => {
        if (d.id === id) {
          d.error = error;
        }
        return d;
      })
    );
  };

  async function updateAPI(id, data) {
    updateStatus(id, 1);
    return dispatch(updateBulkService({ id, data })).then((res) => {
      if (res.type === "services/updateBulk/fulfilled") {
        updateStatus(id, 2);
      } else {
        updateStatus(id, 3);
        let err = "Error update service";
        if (res.payload?.status === 404) {
          err = "Service not found";
        } else if (res.payload?.status === 422) {
          err = JSON.stringify(res.payload.errors);
        }
        updateError(id, err);
      }
    });
  }

  async function updateInBatches() {
    setProcessing(true);
    for (let i = 0; i < data.length; i += BatchSize) {
      const batch = data.slice(i, i + BatchSize);
      await Promise.all(
        batch.map(({ id, key, status, error, ...rest }) => updateAPI(id, rest))
      );
    }
    setProcessing(false);
    setProcessed(true);
  }

  return (
    <>
      <Table
        dataSource={data}
        columns={getColumns()}
        size="small"
        pagination={{
          position: ["topRight"],
          showTotal: (total) => `${total} services to update`,
        }}
      />
      <Row className="mt-30">
        <Col span={12}>
          {!processing && <Button onClick={props.onCancel}>Cancel</Button>}
        </Col>
        <Col span={12} className="ta-r">
          {!processing && !processed && (
            <Button type="primary" onClick={updateInBatches}>
              Update
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}
