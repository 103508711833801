import { Button, Typography } from "antd";
import React from "react";
import logo from "../../assets/img/logo_full.svg";
import styles from "../auth/Auth.module.css";

const { Paragraph } = Typography;

export function NotFound() {
  return (
    <div className={styles.loginContainer}>
      <div>
        <div className="ta-c">
          <img src={logo} alt="logo" width="250" />
        </div>
        <br />
        <Paragraph className="fs-3 ta-c">
          It seems the link is broken, please visit another page!
        </Paragraph>

        <Button className="w-100 mt-30" href="/">
          Home
        </Button>
      </div>
    </div>
  );
}
