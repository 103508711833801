import logo from "../../assets/img/logo_full.svg";
import styles from "./Legal.module.css";

export function Privacy() {
  return (
    <>
      <div className={styles.legalContainer}>
        <div className="ta-c mt-10">
          <div>
            <img src={logo} alt="logo" height="100" />
          </div>
        </div>
        <div className="ta-c mt-25 mb-30 fs-6">Privacy Policy</div>
        <div class="container bg-white b-shadow">
          <p>Welcome to Humanitas!</p>
          <p>
            Humanitas Technologies, Inc.&nbsp;
            <b>(“Humanitas”, “we”, “us”, or “our”)</b> has prepared this Privacy
            Policy to describe to you our practices regarding the Personal
            Information (as defined below) that we collect from users of our
            website, located at https://humanitas.ai (the <b>“Website”</b>) and
            through the use of our web application, <b>(Humanitas)</b>, the
            Website and Humanitas together are collectively referred to as the
            <b>“Services”</b>. This Privacy Policy applies to your use of the
            Services but does not apply to any website, offering, product or
            service of any third party company, even if such third party website
            links to our Services. We do not control any such third party
            websites, offerings, products or services so please always review
            those applicable privacy policies before deciding to provide any
            information. If you do not consent to any of the terms included in
            this Privacy Policy, please do not use, or if you are already using,
            immediately discontinue your use of the Services.
          </p>
          <div className="box">
            <b>Users under the age of 16, please take note!</b>
            <p>
              <b>
                THIS PRIVACY POLICY ONLY APPLIES TO USERS WHO ARE 16 YEARS OF
                AGE AND OVER. USERS UNDER THE AGE OF 16 ARE NOT PERMITTED TO USE
                THE SERVICES.
              </b>
            </p>
          </div>
          <br />
          <p>
            <b>Website and Humanitas.</b>
            <p>
              As a part of the Services, you may access and login into
              Humanitas. Humanitas allows you to connect with other users of the
              Services around the globe, to donate to charities, to communicate
              and share ideas, feedback and other content and materials, as
              further described herein. The Website provides users with the
              chance to learn more about Humanitas. Through the Website, users
              may view the various services offered by Humanitas, learn more
              about Humanitas and post comments and thoughts on the Website
              blog.
            </p>
          </p>
          <br />
          <p>
            <b>How the Services Work.</b>
            <p>
              Our Services allow you to connect and communicate with other
              members around the globe, in order to share ideas, to donate to
              charities, and to communicate with certain third parties such as
              businesses, governments, and non-governmental organizations
              (“NGOs”), in order to participate in hosted “campaigns”!
            </p>
            <p>
              So we are clear about the terminology we are using, when we use
              the phrase <b>“Personal Information”</b> in this Privacy Policy,
              we mean information that allows someone to identify or contact
              you, including, for example, your name, address, telephone number,
              e-mail address, as well as any other non-public information about
              you that is associated with or linked to any of the foregoing
              information. When we use the phrase <b>“Anonymous Information”</b>
              &nbsp; in this Privacy Policy, we mean information that is not
              associated with or linked to your Personal Information. Anonymous
              Information does not enable identification of or contact with
              individual persons.
            </p>
          </p>
          <br />
          <p>
            <b>Children.</b>
            <p>
              Protecting the privacy of children is very important to us. We do
              not collect or maintain Personal Information from people we
              actually know are under 16 years of age.
            </p>
          </p>
          <br />
          <p>
            <b>California Privacy Rights.</b>
            <p>
              Subject to certain limits under California Civil Code § 1798.83,
              California residents may request certain information regarding our
              disclosure of information to third parties or their direct
              marketing purposes. To make such a request, please contact us as
              specified below in this Privacy Policy.
            </p>
          </p>
          <br />
          <p>
            <b>A Note Regarding International Use and Data Transfers.</b>
            <p>
              Humanitas is based in the U.S. If you are a user of the Services
              outside of those countries, by visiting the Website and/or using
              Humanitas, you acknowledge and agree that your Personal
              Information may be processed for the purposes identified in the
              Privacy Policy in the country in which it was collected as well as
              in in other countries, including the United States, where laws
              regarding processing of Personal Information may be less stringent
              than and less protective of Personal Information than the laws in
              your country.&nbsp;
              <b>
                By providing your information and agreeing to the terms of this
                Privacy Policy, you consent to such transfer.
              </b>
            </p>
          </p>
          <br />
          <p>
            <b>Information We Collect.</b>
            <p>
              <u>Personal Information</u>
            </p>
            <p>
              In general, the Personal Information we gather enables us to
              provide the Services to you and helps us learn more about the use
              of the Services.
            </p>
            <p>
              <u>
                Specifically, we collect the following types of Personal
                Information:
              </u>
              <ul>
                <li>
                  <i>Voluntary Information</i>: We also collect other types of
                  Personal Information that you provide to us voluntarily, such
                  as information that you provide directly to us in order to
                  enable us to provide you with the Services. We may also
                  receive requested information if you contact us via e-mail
                  regarding support for the Website. Usage/Account Information:
                  We collect Personal Information that you submit to us
                  voluntarily at registration and in the course of your use of
                  the Website, such as city, email, phone number, gender, and
                  date of birth. To use Humanitas, we may require you to create
                  an “Account”.
                </li>
                <li>
                  <i>Indexing/Third Parties</i>: We may obtain information about
                  you from third parties or through our indexing and analytics
                  tools.
                </li>
                <li>
                  <i>Charitable donations</i>: We will obtain payment
                  information such as Plaid, Stripe, PayPal, or other
                  third-party payment information (when required for the purpose
                  of donations)
                </li>
                <li>
                  <i>Communications</i>: When you communicate with us by sending
                  us an email, we will collect and store any information that is
                  contained in your communications with us.
                </li>
                <li>
                  <i>Surveys</i>: When you participate in one of our surveys, we
                  may collect additional profile information.
                </li>
                <li>
                  <i>
                    Information You Provide to Facebook and other Social
                    Networking Sites
                  </i>
                  : The Services allow users to access Facebook to interact with
                  friends and to share on Facebook through Wall and friends’
                  News Feeds. If you are already logged into the Services and
                  Facebook or another SNS, when you click on “Connect with
                  Facebook,” or a similar connection on another SNS, you will be
                  prompted to merge your profiles. If you are already logged
                  into the Services but not logged into Facebook or another SNS
                  that we support, when you click on “Connect with Facebook,” or
                  a similar connection on another SNS, you will be prompted to
                  enter your SNS credentials or to “Sign Up” for the SNS. By
                  proceeding, you are allowing the Services to access your
                  information and you are agreeing to the Facebook or other SNS’
                  Terms of Use in your use of the Services. Conversely, if you
                  are not currently registered as a user of the Services, and
                  you click on “Sign in” using Facebook or another SNS that we
                  support, you will first be asked to enter your Facebook or SNS
                  credentials and then be given the option to register for the
                  Services. In this case, we may receive information from
                  Facebook or another SNS to make it easier for you to create an
                  Account on the Site and show our relevant content from your
                  Facebook or SNS friends. Once you register on the Site and
                  connect with Facebook or another SNS, you will be able to
                  automatically post recent activity back to Facebook or the
                  other SNS. Any information that we collect from your Facebook
                  or other SNS account may depend on the privacy settings you
                  have with that SNS, so please consult the SNS’ privacy and
                  data practices. You have the option to disable Facebook
                  Connect at any time by logging into your Account through the
                  Site and going to settings, “Linked Accounts,” and then
                  unselecting “Facebook.” Further, you can edit privacy settings
                  for the reviews that appear on Facebook or disconnect your
                  Services activity stream by visiting the Facebook Applications
                  Settings page.
                </li>
                <li>
                  <i>Location Information</i>: If you have enabled location
                  services on your phone, we collect your location information.
                  If you do not want this information collected by us, you can
                  disable location services on your phone.
                </li>
                <li>
                  <i>Other</i>: We may also collect Personal Information at
                  other points through our Services that state that Personal
                  Information is being collected.
                </li>
              </ul>
            </p>
            <p>
              <u>Anonymous Information</u>
            </p>
            <p>
              <u>We collect the following types of Anonymous Information:</u>
            </p>
            <p>
              <ul>
                <li>
                  <i>Information Collected by Our Servers</i>: To make our
                  Website a more useful to you, our servers (which may be hosted
                  by a third party service provider) collect information from
                  you, including your browser type, operating system, Internet
                  Protocol <b>(“IP”)</b> address (a number that is automatically
                  assigned to your computer when you use the Internet, which may
                  vary from session to session), domain name, and/or a date/time
                  stamp for your visit.
                </li>
                <li>
                  <i>Log Files</i>: As is true of most websites, we gather
                  certain information automatically and store it in log files.
                  This information includes IP addresses, browser type, Internet
                  service provider <b>(“ISP”)</b>, referring/exit pages,
                  operating system, date/time stamp, and clickstream data. We
                  use this information to analyze trends, administer the
                  Website, track users’ movements around the Website, gather
                  demographic information about our user base as a whole, and
                  better tailor our Website to our users’ needs. For example,
                  some of the information may be collected so that when you
                  visit the Website again, it will recognize you and the
                  information could then be used to serve advertisements and
                  other information appropriate to your interests.
                </li>
                <li>
                  <i>Cookies</i>: Like many online services, we use cookies to
                  collect information. <b>“Cookies”</b> are small pieces of
                  information that a website sends to your computer’s hard drive
                  while you are viewing the website. We may use both session
                  Cookies (which expire once you close your web browser) and
                  persistent Cookies (which stay on your computer until you
                  delete them) to provide you with a more personal and
                  interactive experience on our Website. This type of
                  information is collected to make the Website more useful to
                  you and to tailor the experience with us to meet your special
                  interests and needs.
                </li>
                <li>
                  <i>Pixel Tags</i>: In addition, we use <b>“Pixel Tags”</b>
                  &nbsp; (also referred to as clear Gifs, Web beacons, or Web
                  bugs). Pixel Tags are tiny graphic images with a unique
                  identifier, similar in function to Cookies, which are used to
                  track online movements of users on our webpages or apps,
                  including their clicks and “swipes”. In contrast to Cookies,
                  which are stored on a user’s computer hard drive, Pixel Tags
                  are embedded invisibly in Web pages. Pixel Tags also allow us
                  to send e-mail messages in a format users can read, and they
                  tell us whether e-mails have been opened to ensure that we are
                  sending only messages that are of interest to our users. We
                  may use this information to reduce or eliminate messages sent
                  to a user. We do not tie the information gathered by Pixel
                  Tags to our users’ Personal Information.
                </li>
              </ul>
            </p>
            <p>
              <u>Disclosure – Analytics</u>
            </p>
            <p>
              We may use third party analytics services (including, but not
              limited to, Google Analytics) to help analyze how users use the
              Services. Analytics services use Cookies to collect information
              such as how often users open the page(s) of the Website and what
              features they use in the Services. We use this information to
              improve the Services. The analytics services’ ability to use and
              share information collected by them is restricted by their terms
              of use and privacy policy, which you should refer to for more
              information about how these entities use this information, and how
              you can opt-out of their use of such information.
            </p>
            <p>
              <u>Information Collected from You About Others</u>
            </p>
            <p>
              If you decide to invite a third party to create an Account, we
              will collect your and the third party’s names and e-mail addresses
              in order to send an e-mail and follow up with the third party. We
              rely upon you to obtain whatever consents from the third party
              that may be required by law to allow us to access and upload the
              third party’s names and e-mail addresses as required above. You or
              the third party may contact us at hello@humanitas.ai to request
              the removal of this information from our database. As part of your
              use of the Services, we provide you the opportunity to connect
              automatically with your friends. We employ various techniques in
              order to facilitate friends finding each other on our service,
              including offering contact importer tools to facilitate adding to
              your contacts (including Facebook Friends and contacts in your
              address book) so that you can more readily ask your contacts to
              join and communicate with you through the Services. With your
              permission, we will access your stored contacts and import your
              contacts’ names, e-mail addresses, phone numbers, image,
              geographic location and Facebook IDs to facilitate automatic
              connection with your friends. We do this only for contact matching
              to help your friends and you find each other. Also, when you
              invite friends to the Services, we will access your Address Book
              and we will import your contacts names and phone numbers in order
              to facilitate the invitation. The e-mail that is sent to your
              friends will come from your e-mail address so that your friends
              know that you want to invite them to visit the Site. We do not
              store any passwords you provide as part of the contact import
              process. We also collect some settings that help us to interpret
              your data, such as the language and keyboard settings that you
              have established, and the phone’s carrier, mobile network code and
              mobile country code.
            </p>
          </p>
          <br />
          <p>
            <b>How We Use Information.</b>
            <p>
              <u>Personal Information</u>
            </p>
            <p>
              <u>We will use your Personal Information to:</u>
              <ul>
                <li>Identify you as a user in our system;</li>
                <li>
                  Facilitate the creation of and administration of your Account;
                </li>
                <li>
                  Allow you to communicate and interact with other users of the
                  Services (including without limitation entities that host
                  “campaigns” in which you choose to participate);
                </li>
                <li>Recommend charities for you to donate to;</li>
                <li>
                  Provide you with the Services and complete your transactions;
                </li>
                <li>Provide you with customer support;</li>
                <li>
                  Help understand your needs and tailor the features and content
                  of the Services to you;
                </li>
                <li>
                  Respond to and, as applicable, fulfill your requests, resolve
                  disputes and/or troubleshoot problems;
                </li>
                <li>
                  Verify your compliance with your obligations in our Terms of
                  Service;
                </li>
                <li>
                  Send you administrative e-mail notifications, such as
                  security, or support and maintenance advisories;
                </li>
                <li>
                  Send newsletters, surveys, offers, and other promotional
                  materials related to our Services and for other marketing
                  purposes;
                </li>
                <li>Improve the quality and functionality of the Services;</li>
                <li>Communicate with you about the Services.</li>
              </ul>
              We may also use your Personal Information to create Anonymous
              Information records by excluding information that makes the
              information personally identifiable to you.
            </p>
            <p>
              <u>Anonymous Information</u>
            </p>
            <p>
              We use the Anonymous Information we collect to analyze trends,
              administer and improve the Services, gather demographic
              information about our user base as a whole, and better tailor the
              Services to our users’ needs. However, we reserve the right to use
              Anonymous Information for any purpose, and to disclose Anonymous
              Information publicly without restriction. We provide analytics
              based upon our customer’s use of the Services to prospective
              partners, other customers, advertisers and other third parties. We
              may also disclose, on an anonymous basis, actual statements,
              verbatim, as made by our customers.
            </p>
          </p>
          <br />
          <p>
            <b>How We Disclose Information.</b>
            <p>
              <u>Personal Information</u>
            </p>
            <p>
              <u>We may also disclose certain Personal Information:</u>
              <ul>
                <li>
                  <i>Third Parties Designated by You</i>: When you use the
                  Services, the Personal Information you provide will be shared
                  with the third parties that you authorize to receive such
                  information (e.g., other individuals that you wish to connect
                  with via the Services).
                </li>
                <li>
                  <i>Third Party Partners and Users</i>: When you user the
                  Services, the Personal Information you provide may be shared
                  with other third parties who use the Services and/or certain
                  third parties (e.g., NGOs) who engage you and other users to
                  provide various ideas, thoughts, videos (as defined herein),
                  feedback and other comments, in order to participate in other
                  “campaigns” or special offerings via the Services.
                </li>
                <li>
                  <i>Third Party Service Providers</i>: We may share your
                  Personal Information with our third party service providers
                  who provide services such as third party data (e.g., GuideStar
                  USA, Inc.), data analytics, website hosting, payment
                  processing (e.g., Stripe), fiscal partner (e.g., Philanthropic
                  Ventures Foundation, EIN: 94-3136771), information technology,
                  customer service, email delivery, auditing, and other
                  services.
                </li>
                <li>
                  <i>Social Networking Sites</i>: Some of our Services,
                  specifically Humanitas web application, may enable you to post
                  content to SNS (e.g., Facebook or Twitter). If you choose to
                  do this, we will provide information to such SNS' in
                  accordance with your elections. You acknowledge and agree that
                  you are solely responsible for your use of those websites and
                  that it is your responsibility to review the terms of use and
                  privacy policy of the third party provider of such SNS'. We
                  will not be responsible or liable for: (i) the availability or
                  accuracy of such SNS'; (ii) the content, products or services
                  on or availability of such SNS'; or (iii) your use of any such
                  SNS'
                </li>
                <li>
                  <i>Public Profile</i>: Certain portions of the information you
                  provide to us may also be displayed in your Profile. In order
                  for your Profile to be made public, you must go to your
                  profile settings and then to profile visibility. By default,
                  your Profile is publicly visible, though you may modify
                  visibility of certain information in your profile settings.
                  Your photos, posts, friends, and other content you post
                  through the Services are also meant for pubic consumption. We
                  may display this content through the Services and further
                  distribute it to a wider audience through third party sites
                  and services. Once displayed on publicly viewable web pages,
                  that information can be collected and used by others. We
                  cannot control who reads your postings or what other users may
                  do with the information that you voluntarily post, so it is
                  very important that you do not put Personal Information in
                  your posts. Once you have posted information publicly, while
                  you will still be able to edit and delete it on the Site, you
                  will not be able to edit or delete such information cached,
                  collected, and stored elsewhere by others (e.g., search
                  engines).
                </li>
                <li>
                  <i>Affiliates</i>: We may share some or all of your Personal
                  Information with our parent company, subsidiaries, joint
                  ventures, or other companies under a common control&nbsp;
                  <b>(“Affiliates”)</b>, if we have such Affiliates in the
                  future, in which case we will require our Affiliates to honor
                  this Privacy Policy.
                </li>
                <li>
                  <i>Corporate Restructuring</i>: We may share some or all of
                  your Personal Information in connection with or during
                  negotiation of any merger, financing, acquisition or
                  dissolution, transaction or proceeding involving sale,
                  transfer, divestiture, or disclosure of all or a portion of
                  our business or assets. In the event of an insolvency,
                  bankruptcy, or receivership, Personal Information may also be
                  transferred as a business asset. If another company acquires
                  our company, business, or assets, that company will possess
                  the Personal Information collected by us and will assume the
                  rights and obligations regarding your Personal Information as
                  described in this Privacy Policy.{" "}
                </li>
                <li>
                  <i>Other Disclosures</i>: Regardless of any choices you make
                  regarding your Personal Information (as described below), we
                  may disclose Personal Information if it believes in good faith
                  that such disclosure is necessary to (a) resolve disputes,
                  investigate problems, or enforce our Terms and Conditions; (b)
                  comply with relevant laws or to respond to requests from law
                  enforcement or other government officials relating to
                  investigations or alleged illegal activity, in which case we
                  can (and you authorize us to) disclose Personal Information
                  without subpoenas or warrants served on us; or (c) protect and
                  defend our rights or property or the rights and property of
                  you or third parties; and/or (d) investigate or assist in
                  preventing any violation or potential violation of the law,
                  this Privacy Policy, or our Terms and Conditions.
                </li>
              </ul>
            </p>
          </p>
          <br />
          <p>
            <b>Your Choices Regarding Your Information.</b>
            <ul>
              <li>
                <i>Email Communications</i>: We will communicate with you
                primarily in the form of emails. You can manage the frequency
                and/or opt out of receiving these communications by changing
                your settings in your Account and/or clicking the “Unsubscribe”
                link at the bottom of each email. Please note that even if you
                unsubscribe or opt-out, we may still send you Website-related
                communications (e.g., emails related to your Account).
              </li>
              <li>
                <i>Cookies</i>: If you decide at any time that you no longer
                wish to accept cookies from our Services for any of the purposes
                described above, then you can instruct your browser, by changing
                its settings, to stop accepting cookies or to prompt you before
                accepting a cookie from the websites you visit. Consult your
                browser’s technical information. If you do not accept cookies,
                however, you may not be able to use all portions of the Services
                or all functionality of the Services.
              </li>
              <li>
                <i>De-Linking SNS</i>: If you decide at any time that you no
                longer wish to have your SNS account (e.g., Facebook) linked to
                your Account, then you may de-link the SNS account in the
                “settings” section in your account settings. You may also manage
                the sharing of certain Personal Data with us when you connect
                with us through an SNS, such as through Facebook Connect. Please
                refer to the privacy settings of the SNS to determine how you
                may adjust our permissions and manage the interactivity between
                the Services and your social media account or mobile device.
              </li>
              <li>
                <i>Changing or Deleting Your Personal Information</i>: You may
                review, change, update, correct or delete the Personal
                Information in your Account by logging into your Account or by
                contacting us. You authorize us to use and disclose any new or
                modified information that you provide in accordance with this
                Privacy Policy, and you agree that we are under no obligation to
                delete or modify information that you have previously chosen to
                provide us. If you completely delete all of your Personal
                Information, then your account may become deactivated, although
                your profile and any content associated therewith will remain on
                the Services unless you have deleted them. We may also retain an
                archived copy of your records as required by law or for
                legitimate business purposes. Please remember, however, if we
                have already disclosed some of your Personal Information to
                third parties, we cannot access that Personal Information any
                longer and cannot force the deletion or modification of any such
                information by the parties to whom we have made those
                disclosures. Certain information is necessary in order for us to
                make the Services available; therefore, if you delete such
                necessary information you may not be able to use the Services.
                Please note that even though you may request the deletion of
                your Personal Information by us, we may be required (by law or
                otherwise, such as to prevent fraud, resolve disputes,
                troubleshoot problems, or enforce our Terms of Service) to keep
                this information and not delete it (or to keep this information
                for a certain time) in which case we will comply with your
                deletion request only after we have fulfilled such requirements.
                When we delete Personal Information, it will be deleted from the
                active database, but may remain in our archives.
              </li>
              <li>
                <i>Applications</i>: You can stop all collection of information
                by Humanitas by deactivating your account on Humanitas. You may
                request to deactivate your account by emailing us at
                hello@humanitas.ai.
              </li>
            </ul>
          </p>
          <br />
          <p>
            <b>Feedback.</b>
            <p>
              If you provide feedback to us, we may use and disclose such
              feedback in accordance with the terms in our Terms of Service,
              provided we do not associate such feedback with your Personal
              Information. We may publish user feedback from time to time,
              provided that we will obtain your consent prior to posting any
              feedback that is associated with your Personal Information. We
              will collect any information contained in such feedback and will
              treat the Personal Information in it in accordance with this
              Privacy Policy, provided that we may contact you in regards to
              such feedback.
            </p>
          </p>
          <br />
          <p>
            <b>Information Disclosed to Third Parties.</b>
            <p>
              This Privacy Policy addresses only our use and disclosure of
              information we collect from and/or about you on the Website. If
              you disclose information to others, including any users that you
              meet through the Services and decide to continue interacting with
              separate from or outside of your use of the Services, or authorize
              us to do the same under this Privacy Policy, the use and
              disclosure restrictions contained in this Privacy Policy will not
              apply to any third party. We do not control the privacy policies
              of third parties, and you are subject to the privacy policies of
              those third parties where applicable.
            </p>
            <p>
              The Services may contain content or links to other websites that
              are not owned or controlled by us. We have no control over, do not
              review and are not responsible for the privacy policies of or
              content displayed on such other websites. When you click on such a
              link, you will leave our Website and go to another site. During
              this process, another entity may collect Personal Information or
              Anonymous Information from you. Please review the privacy policy
              of any new site you visit.
            </p>
          </p>
          <br />
          <p>
            <b>Humanitas & Services, Specifically.</b>
            <p>
              We may also collect non-personal information from your mobile
              device if you have downloaded our Humanitas mobile application.
              This information is generally used to help us deliver the most
              relevant information to you. Examples of information that may be
              collected and used include your geographic location, how you use
              Humanitas and information about the type of device you use. In
              addition, in the event Humanitas crashes on your mobile device, we
              will receive information about your mobile device model software
              version and device carrier, which allows us to identify and fix
              bugs and otherwise improve the performance of our Humanitas. This
              information is sent to us as aggregated information and is not
              traceable to any individual and cannot be used to identify an
              individual.{" "}
            </p>
          </p>
          <br />
          <p>
            <b>How We Respond to Do Not Track Signals.</b>
            <p>
              We do not currently respond to “do not track” signals or other
              mechanisms that might enable consumers to opt out of tracking on
              the Website.
            </p>
          </p>
          <br />
          <p>
            <b>Identifying the Data Controller and Processor.</b>
            <p>
              Data protection law in certain jurisdictions (such as the European
              Union) differentiates between the “controller” and “processor” of
              information. In general, you are the controller of all data and
              information submitted through the Services, including all Personal
              Information. In general, Humanitas is the processor of all such
              data and information, including Personal Information, and the
              controller of all other information made available through the
              Services. Humanitas provides the Services in different parts of
              the world but is established in the United States and operates out
              of the United States, though we may process data at data centers
              in or near your country through use of our cloud server providers,
              such as Amazon Web Services (AWS).
            </p>
          </p>
          <br />
          <p>
            <b>Your Rights.</b>
            <p>
              Users located in certain countries, including the European
              Economic Area, have certain statutory rights in relation to their
              personal data. Subject to any exempted provided by law, you may
              have the right to request access to information, as well as to
              seek to update, delete or correct any such information. If you are
              unable to locate or use the applicable settings, please contact us
              for additional assistance at hello@humanitas.ai with the heading
              “Data Rights”.
            </p>
            <p>
              To the extent that Humanitas’ processing of your Personal
              Information is subject to the General Protection Regulation,
              Humanitas relies on its legitimate interests, described herein, to
              process your Personal Information. Humanitas may also process any
              other information that constitutes your Personal Information for
              direct marketing purposes and you have a right to object to
              Humanitas’ use of such Personal Information for this purpose at
              any time.
            </p>
          </p>
          <br />
          <p>
            <b>Information Choices and Changes; Data Protection Authority.</b>
            <p>
              <i>Data Protection Authority</i>: Subject to applicable law, you
              also have rights to (i) restrict Humantias’ use of information
              that constitutes your Personal Information and (ii) lodge a
              compliant with your local data protection authority. For example,
              if you are based in the UK, you can file a complaint with the
              Information Commissioner’s Office by visiting https://ico.org.uk.
            </p>
            <p>
              <i>Removing videos</i>: If you desire to remove a video posted to
              or through the Services, you can delete it through the
              application.
            </p>
            <p>
              You can prevent the further collection or use of your personal
              information by terminating your use of the Services and
              deactivating your account on Humanitas by emailing us at
              hello@humanitas.ai.
            </p>
            <p>
              Our marketing emails explain how to “opt-out.” If you opt out, we
              may still send you non-marketing emails. Non-marketing emails
              include emails about your accounts and our business dealings with
              you. You may send requests about your personal information to our
              Contact Information below such as to change contact choices,
              opt-out of our sharing of your personal information with others,
              and review, update, correct or delete your personal information.
              We will use commercially reasonable efforts to honor your request,
              provided, however, that videos can only be removed per the
              procedures outlined above. We may retain an archived copy of your
              records as required by law or for legitimate business purposes.
              You can typically remove and reject cookies from our Site with
              your browser settings. Many browsers are set to accept cookies
              until you change your settings. If you remove or reject our
              cookies, it could affect how our Site works for you.
            </p>
          </p>
          <br />
          <p>
            <b>Changes to this Privacy Policy.</b>
            <p>
              This Privacy Policy may be updated from time to time for any
              reason. We will notify you of any changes to our Privacy Policy by
              posting the new Privacy Policy here and we will change the “Last
              Updated” date below. You should consult this Privacy Policy
              regularly for any changes.
            </p>
          </p>
          <br />
          <p>
            <b>Contacting Humanitas.</b>
            <p>
              We are here to help answer any questions you have about the
              Services and how we use your Personal Information. If you have any
              questions or concerns or complaints about our Privacy Policy or
              our data collection or processing practices, or if you want to
              report any security violations to us, please contact us at
              hello@humanitas.ai.
            </p>
          </p>
          <div className={styles.updated}>Last Updated: 8th April 2022</div>
        </div>
      </div>
    </>
  );
}
