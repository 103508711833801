export const categoryTags = {
  "addiction & recovery": [
    "detox",
    "12-step",
    "individual counseling",
    "support groups",
    "mentoring",
    "residential treatment",
    "support & service animals",
    "rehabilitation treatment",
    "harm reduction",
    "medications for addiction",
    "sober living",
    "help find/apply to resources",
    "drug testing",
  ],
  "advocacy & legal aid": [
    "discrimination & civil rights",
    "mediation",
    "notary",
    "representation",
    "workplace rights",
    "identification recovery",
    "civic engagement",
    "citizenship & immigration",
    "translation & interpretation",
    "case management",
    "help find/apply to resources",
    "tenant rights",
    "low income legal advisor",
    "healthcare advocacy",
  ],
  "animal welfare": [
    "animal rescue",
    "animal shelter",
    "veterinary care",
    "spay/neuter programs",
    "pet food assistance",
  ],
  "adoption & foster care": [
    "post-adoption support",
    "foster parenting",
    "foster placement",
    "adoption counseling",
    "guardianship",
    "parenting education",
    "family counseling",
    "help find/apply to resources",
    "adoption case management",
    "foster care case management",
    "group home",
  ],
  "disability support": [
    "independent living",
    "assistive technology",
    "disability benefits",
    "disability screening",
    "overnight camp",
    "translation & interpretation",
    "recreation",
    "speech therapy",
    "in-home support",
    "community support services",
    "daytime care",
    "occupational therapy",
    "help find/apply to resources",
    "residential care",
    "support & service animals",
    "daily life skills",
    "case management",
  ],
  "disaster response": [
    "humanitarian aid and relief",
    "help find missing persons",
    "weather relief",
    "disaster recovery",
    "disaster preparedness",
  ],
  education: [
    "literacy",
    "leadership skills",
    "ged/high-school equivalency",
    "head start",
    "find school",
    "mentorship",
    "preschool",
    "special education",
    "tutoring",
    "scholarships",
    "pay for school",
    "art classes",
    "college readiness",
  ],
  "elderly care": [
    "assisted living",
    "independent living",
    "nursing home",
    "translation & interpretation",
    "recreation",
    "community support services",
    "occupational therapy",
    "help find/apply to resources",
    "skilled nursing",
    "finding in-home care",
  ],
  "escaping violence": [
    "help escaping violence",
    "immediate safety",
    "personal safety",
    "physical safety",
    "safety education",
    "translation & interpretation",
    "in-home support",
    "virtual support",
    "community support services",
    "help hotlines",
    "help find/apply to resources",
    "one-on-one support",
  ],
  "financial assistance": [
    "credit repair",
    "financial education",
    "retirement benefits",
    "savings program",
    "tax preparation",
    "debt relief",
    "grants",
    "emergency funds",
    "unemployment benefits",
  ],
  childcare: [
    "help pay for childcare",
    "help find emergency backup care",
    "help find ongoing childcare",
  ],
  clothing: ["find clothing", "pay for clothing"],
  food: [
    "help find/apply to resources",
    "help pay for food",
    "food delivery",
    "food pantry",
    "community gardens",
    "prepared meals",
    "help hotlines",
    "nutrition education",
  ],
  "find housing": [
    "find short-term housing",
    "find long-term rental",
    "help find/apply to resources",
    "buy a home",
    "emergency housing and shelter",
  ],
  "find medical care": [
    "alternative medicine",
    "disease management",
    "disease screening",
    "hearing tests",
    "help find healthcare",
    "hospital treatment",
    "medical supplies",
    "pain management",
    "physical therapy",
    "primary care",
    "prostheses",
    "relief for caregivers",
    "vaccinations",
    "vision care",
    "womens health",
    "dental care",
    "outpatient treatment",
    "toys & gifts",
    "translation & interpretation",
    "speech therapy",
    "in-home support",
    "help hotlines",
    "occupational therapy",
    "help find/apply to resources",
    "residential treatment",
    "skilled nursing",
    "nutrition education",
    "palliative care",
    "end of life care",
    "health education",
    "postnatal care",
    "maternity care",
    "abortion",
  ],
  "medication management": [
    "medication management",
    "medications for addiction",
    "medications for mental health",
    "prescription assistance",
    "help find/apply to resources",
    "help administering medication",
  ],
  "mental healthcare": [
    "early childhood intervention",
    "grief counseling",
    "mental health evaluation",
    "add/adhd",
    "anger management",
    "group therapy",
    "psychiatric emergency services",
    "outpatient treatment",
    "family counseling",
    "individual counseling",
    "support groups",
    "spiritual support",
    "toys & gifts",
    "recreation",
    "case management",
    "in-home support",
    "virtual support",
    "community support services",
    "help hotlines",
    "mentoring",
    "occupational therapy",
    "help find/apply to resources",
    "one-on-one support",
    "residential treatment",
    "support & service animals",
    "find psychiatrists",
    "find psychologists",
    "find therapists",
    "medications for mental health",
    "postpartum depression",
    "couples counseling",
    "crisis intervention",
  ],
  "navigating death": [
    "grief counseling",
    "burial & funeral help",
    "end of life care",
    "spiritual support",
    "case management",
    "in-home support",
    "virtual support",
    "help hotlines",
    "residential care",
  ],
  "pay for medical care": [
    "health insurance",
    "dental insurance",
    "help pay for healthcare",
  ],
  "pay for housing": [
    "home maintenance and repairs",
    "buy a home",
    "help pay for rent",
    "home goods",
  ],
  "personal care": ["personal care items", "blankets & fans", "toys & gifts"],
  "childbirth resources": [
    "baby clothes",
    "baby supplies",
    "safe sleep supplies",
    "feeding and nursing supplies",
    "postpartum supplies",
    "baby toys and gifts",
    "safety education",
  ],
  "sexual & reproductive health": [
    "family planning",
    "fertility",
    "pregnancy tests",
    "birth control",
    "hiv treatment",
    "sex education",
    "std/sti treatment & prevention",
    "screening & exams",
    "translation & interpretation",
    "case management",
    "help find/apply to resources",
    "one-on-one support",
  ],
  transportation: [
    "pay for car",
    "public transportation",
    "affordable rideshare",
    "car maintenance",
    "help pay for gas",
    "transportation for healthcare",
    "help pay for auto insurance",
    "safety education",
  ],
  utilities: [
    "efficiency upgrades",
    "computer or internet access",
    "help pay for internet or phone",
    "help pay for gas",
    "help pay for utilities",
  ],
  "career development": [
    "help find work",
    "unemployment benefits",
    "interview training",
    "resume development",
    "supported employment",
    "skills & training",
  ],
  "exercise & fitness": [
    "recreation",
    "find gyms/gym memberships",
    "exercise advice",
    "find exercise programs",
  ],
};

export const servingTags = [
  "lgbtqia+",
  "african american",
  "asian",
  "latino",
  "native american",
  "alaskan natives",
  "active duty",
  "veterans",
  "for-children",
  "for-seniors",
  "life threatening disease",
  "for-disabled",
  "female",
  "male",
  "transgender or non-binary",
];

export const personaTags = [
  "all-users",
  "age-above-60",
  "age-30-60",
  "age-below-30",
  "has-children-under-18",
  "need-transportation",
  "need-childcare",
  "need-housing",
  "need-food",
  "need-debt-relief",
  "need-general-savings",
];

export const languages = [
  "Armenian",
  "English",
  "Karen",
  "Ukrainian",
  "Panjabi",
  "Russian",
  "Nepali",
  "K'iche'",
  "Swahili",
  "Greek",
  "Samoan",
  "Persian",
  "Chinese",
  "Malay",
  "Thai",
  "Chinese",
  "Hmong",
  "Burmese",
  "Punjabi",
  "Cape Verdean Creole",
  "Haitian",
  "French",
  "Urdu",
  "Navajo",
  "Romanian",
  "Ilocano",
  "Amharic",
  "Interpretation Services Available",
  "Italian",
  "Yiddish",
  "Kinyarwanda",
  "Khmer",
  "Samoan",
  "Czech",
  "Korean",
  "Esperanto",
  "Haitian",
  "Tagalog",
  "Cebuano",
  "Turkish",
  "Slovak",
  "Thai",
  "Vietnamese",
  "Dutch",
  "Yiddish",
  "Serbian",
  "Javanese",
  "Swahili",
  "Finnish",
  "Arabic",
  "Serbo-Croatian",
  "Japanese",
  "Spanish",
  "Spanish",
  "Danish",
  "Albanian",
  "Yoruba",
  "Indonesian",
  "Nepali",
  "Korean",
  "Croatian",
  "Tamil",
  "Hindi",
  "Japanese",
  "Gujarati",
  "Karen",
  "Vietnamese",
  "Lao",
  "Norwegian",
  "Hawaiian",
  "Portuguese",
  "Punjabi",
  "Bengali",
  "American Sign Language",
  "Lithuanian",
  "Malayalam",
  "Telugu",
  "Urdu",
  "Persian",
  "German",
  "French",
  "American Sign Language",
  "Polish",
  "Hebrew",
  "Swedish",
  "Somali",
  "Hungarian",
  "Tagalog",
  "Swedish",
  "Portuguese",
  "Hebrew",
  "English",
];

export const PilotStoresGE = {
  1619: "1619 - Edgewood - Supermarket",
  3003: "3003 - Southside Works - GetGo Standalone",
  3021: "3021 - McKnight Road - GetGo Standalone",
  3064: "3064 - Baum Blvd - GetGo Standalone",
  3107: "3107 - Lawrenceville - GetGo Standalone",
  3147: "3147 - Edgewood - GetGo Cafe + Market",
  3172: "3172 - Waterworks - GetGo Standalone",
  9501: "9501 - Edgewood - MFC",
  "0091": "0091 - Cedar Avenue - Supermarket",
  "0074": "0074 - Parkway Center - Supermarket",
  "0072": "0072 - Waterworks - Market District",
  "0061": "0061 - South Side - Supermarket",
  "0025": "0025 - West View - Supermarket",
  "0035": "0035 - Cochran Road - Supermarket",
  "0067": "0067 - McIntyre Square - Supermarket",
  OKGD: "OK Grocery Dry - Retail Support Center",
  "0002": "0002 - Kennedy Township - Supermarket",
  "0063": "0063 - Greenfield - Supermarket",
  "0040": "0040 - Shadyside - Market District",
  "0077": "0077 - Braddock Hills - Supermarket",
  "0065": "0065 - Brighton Heights - Supermarket",
  "0078": "0078 - Rodi Road - Supermarket",
  OKGP: "OK Grocery Perishables - Retail Support Center",
  "0038": "0038 - Squirrel Hill - Supermarket",
  "0069": "0069 - Frankstown Road - Supermarket",
};

export const Models = {
  1: "gpt-4o",
  2: "gpt-4-0125-preview",
  3: "gpt-3.5-turbo-0125",
  4: "gpt-3.5-turbo-1106",
  5: "claude-3-5-sonnet-20240620",
  6: "gpt-4o-mini",
  7: "gpt-4o-2024-08-06",
};

export const ModelCosts = {
  1: { p: 5, c: 15 },
  2: { p: 10, c: 30 },
  3: { p: 0.5, c: 1.5 },
  4: { p: 0.5, c: 1.5 },
  5: { p: 3, c: 15 },
  6: { p: 0.15, c: 0.6 },
  7: { p: 2.5, c: 10 },
};
