import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  data: [],
  loading: false,
  errors: null,
  totalPages: 0,
  totalCount: 0,
};

export const getAnnouncements = createAsyncThunk(
  "announcements/index",
  async (data, thunk) => {
    try {
      let response = await API.announcements.index(data.page, data.per);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const createAnnouncement = createAsyncThunk(
  "announcements/create",
  async (data, thunk) => {
    try {
      let response = await API.announcements.create(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const announcementsSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getAnnouncements.pending]: (state) => {
      state.loading = true;
      state.errors = null;
    },
    [getAnnouncements.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.announcements;
      state.totalPages = action.payload.total_pages;
      state.totalCount = action.payload.total_count;
    },
    [getAnnouncements.rejected]: (state, action) => {
      state.loading = false;
      state.errors = ["Error while fetching announcements"];
    },
  },
});

export const { clearErrors } = announcementsSlice.actions;

export const announcementsSelector = (state) => state.announcements;

export default announcementsSlice.reducer;
