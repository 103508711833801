import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../../../api";

const initialState = {
  employees: [],
  loading: false,
  errors: null,
  totalPages: 0,
  totalCount: 0,
  currentCount: 0,
  terminatedCount: 0,
};

export const getEmployees = createAsyncThunk(
  "employees/index",
  async (data, thunk) => {
    try {
      let response = await API.orgs.employees.index(
        data.id,
        data.page,
        data.per,
        data.format
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const createEmployee = createAsyncThunk(
  "employees/create",
  async (data, thunk) => {
    try {
      let response = await API.orgs.employees.create(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const downloadEmployees = createAsyncThunk(
  "employees/download",
  async (data, thunk) => {
    try {
      let response = await API.orgs.employees.index(
        data.id,
        null,
        null,
        data.format
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateBulkEmployees = createAsyncThunk(
  "employees/updateBulk",
  async (data, thunk) => {
    try {
      let response = await API.orgs.employees.bulkUpdate(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateTerminationEmployee = createAsyncThunk(
  "employees/updateTermination",
  async (data, thunk) => {
    try {
      let response = await API.orgs.employees.terminationUpdate(
        data.orgId,
        data.data
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getEmployees.pending]: (state) => {
      state.loading = true;
      state.employees = [];
    },
    [getEmployees.fulfilled]: (state, action) => {
      state.loading = false;
      state.employees = action.payload.employees;
      state.totalPages = action.payload.total_pages;
      state.totalCount = action.payload.total_count;
      state.currentCount = action.payload.current_count;
      state.registeredCount = action.payload.registered_count;
      state.terminatedCount = action.payload.terminated_count;
    },
    [getEmployees.rejected]: (state, action) => {
      state.loading = false;
      state.employees = [];
      state.errors = ["Data not found!"];
    },
  },
});

export const { clearErrors } = employeesSlice.actions;

export const employeesSelector = (state) => state.employees;

export default employeesSlice.reducer;
