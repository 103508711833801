import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../../../api";

const initialState = {
  data: [],
  totalPages: 0,
  totalCount: 0,
  totalSum: 0,
  loading: false,
  errors: null,
};

export const getDonations = createAsyncThunk(
  "getDonations",
  async (data, thunk) => {
    try {
      let response = await API.orgs.donations.getDonations(
        data.id,
        data.from,
        data.to,
        data.page
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const donationsSlice = createSlice({
  name: "donations",
  initialState,
  extraReducers: {
    [getDonations.pending]: (state) => {
      state.loading = true;
    },
    [getDonations.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.donations.map((s) => {
        s.key = s.id;
        return s;
      });
      state.totalCount = action.payload.total_count;
      state.totalSum = action.payload.total_sum;
      state.totalPages = action.payload.total_pages;
    },
    [getDonations.rejected]: (state, action) => {
      state.loading = false;
      state.data = {};
      state.totalPages = 0;
      state.errors = ["Data not found!"];
    },
  },
});

export const donationsSelector = (state) => state.donations;

export default donationsSlice.reducer;
