import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../../../api";

const initialState = {
  needs: {
    data: [],
    loading: false,
    errors: null,
  },
};

export const getNeeds = createAsyncThunk("needs/index", async (data, thunk) => {
  try {
    let response = await API.needs.index(data.nodeId, data.approved);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const registrySlice = createSlice({
  name: "registry",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    clearNeeds: (state) => {
      state.needs.data = [];
    },
  },
  extraReducers: {
    [getNeeds.pending]: (state) => {
      state.needs.loading = true;
    },
    [getNeeds.fulfilled]: (state, action) => {
      state.needs.loading = false;
      state.needs.data = action.payload.needs.map((n) => {
        return { ...n, key: n.id };
      });
    },
    [getNeeds.rejected]: (state, action) => {
      state.needs.loading = false;
      state.needs.errors = action.payload?.errors;
    },
  },
});

export const { clearErrors, clearNeeds } = registrySlice.actions;

export const needsSelector = (state) => state.registry.needs;

export default registrySlice.reducer;
