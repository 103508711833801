import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, message, Modal, Row, Spin, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useCSVReader } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getGifts, giftsSelector, updateBulkGifts } from "./giftsSlice";

const PAGE_SIZE = 50;
const UploadFields = ["name", "value", "pin", "link", "meta"];

export function Gifts(props) {
  const dispatch = useDispatch();
  const { CSVReader } = useCSVReader();
  const { gifts, loading, errors, totalPages, totalCount, remainingCount } =
    useSelector(giftsSelector);
  const [pageNum, setPageNum] = useState(1);
  const [uploadOpened, setUploadOpened] = useState(false);
  const [uploadData, setUploadData] = useState([]);
  const [uploadProcessing, setUploadProcessing] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    if (props.id) {
      loadGifts(pageNum);
    }
  }, [props.id, dispatch]);

  const loadGifts = (page) => {
    dispatch(
      getGifts({
        id: props.id,
        page: page,
        per: PAGE_SIZE,
      })
    );
  };

  const onPageChange = (page) => {
    setPageNum(page);
    loadGifts(page);
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
      render: (id) => {
        return id ? <Link to={`/users/${id}`}>{id}</Link> : "Available";
      },
    },
    {
      title: "PIN",
      dataIndex: "pin",
      key: "pin",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (value) => `$${value}`,
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (link) => (
        <a href={link} target="_blank" rel="noreferrer">
          {link}
        </a>
      ),
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
    },
    {
      title: "Other",
      dataIndex: "meta",
      key: "meta",
      render: (meta) => JSON.stringify(meta),
    },
  ];

  const getUploadColumns = () => {
    return UploadFields.map((f) => {
      let obj = {
        title: f,
        dataIndex: f,
        key: f,
        render: (data) =>
          typeof data === "object" ? JSON.stringify(data) : data,
      };
      return obj;
    });
  };

  const update = () => {
    setUploadProcessing(true);
    dispatch(
      updateBulkGifts({
        id: props.id,
        data: { gifts: uploadData.map(({ key, ...rest }) => rest) },
      })
    ).then((res) => {
      setUploadProcessing(false);
      if (res.type === "gifts/updateBulk/rejected") {
        message.error("Error updating gift cards");
      } else {
        setUploadOpened(false);
        setUploadData([]);
        setPageNum(1);
        loadGifts(1);
      }
    });
  };

  return (
    <>
      <div>
        <Row align="middle">
          <Col span={20}>
            Remaining: <span className="fw-600">{remainingCount}</span>
          </Col>
          <Col span={4} className="ta-r">
            <Tooltip
              title="CSV file with headers - name, value, pin, link, card"
              color="#4867b1"
            >
              <div>
                <CSVReader
                  onUploadAccepted={(results) => {
                    setUploadData(
                      results.data
                        .filter(
                          (d, i) => i !== 0 && d.length === UploadFields.length
                        )
                        .map((d, i) => {
                          let formatted = { key: i };
                          d.forEach((f, j) => {
                            if (j === 4) {
                              formatted["meta"] = { card: f };
                            } else {
                              formatted[UploadFields[j]] = f;
                            }
                          });
                          return formatted;
                        })
                    );
                    setUploadOpened(true);
                  }}
                >
                  {({ getRootProps }) => {
                    return (
                      <Button icon={<PlusOutlined />} {...getRootProps()}>
                        Gift Card Update
                      </Button>
                    );
                  }}
                </CSVReader>
              </div>
            </Tooltip>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Table
            size="small"
            columns={columns}
            loading={loading}
            dataSource={gifts.map((l, i) => {
              return { ...l, ...{ key: i } };
            })}
            className="mt-20"
            pagination={{
              position: ["bottomRight"],
              onChange: onPageChange,
              pageSize: PAGE_SIZE,
              total: totalPages * PAGE_SIZE,
              showSizeChanger: false,
              current: pageNum,
              showTotal: () => (
                <span className="fs-2 c-subtext">{`${totalCount} ${
                  totalCount === 1 ? "gift card" : "gift cards"
                }`}</span>
              ),
            }}
          />
        </Spin>
      </div>

      <Modal
        title="Gift Card Upload"
        open={uploadOpened}
        footer={null}
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        width={1000}
        style={{
          top: 32,
        }}
        onCancel={() => {
          setUploadOpened(false);
          setUploadData([]);
        }}
      >
        <Table
          dataSource={uploadData}
          columns={getUploadColumns()}
          size="small"
          className="mt-10"
          pagination={{
            position: ["bottomRight"],
            showTotal: (total) => `${total} cards to update`,
          }}
        />
        <Row className="mt-30">
          <Col span={12}>
            {!uploadProcessing && (
              <Button
                onClick={() => {
                  setUploadOpened(false);
                  setUploadData([]);
                }}
              >
                Cancel
              </Button>
            )}
          </Col>
          <Col span={12} className="ta-r">
            <Button type="primary" onClick={update} loading={uploadProcessing}>
              Update
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
