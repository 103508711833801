import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  metrics: {
    data: {},
    loading: false,
    errors: null,
  },
};

export const refreshMetrics = createAsyncThunk(
  "metrics/get",
  async (data, thunk) => {
    try {
      let response = await API.metrics.get(data.filter);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [refreshMetrics.pending]: (state) => {
      state.metrics.loading = true;
    },
    [refreshMetrics.fulfilled]: (state, action) => {
      state.metrics.loading = false;
      state.metrics.data = action.payload;
    },
    [refreshMetrics.rejected]: (state, action) => {
      state.metrics.loading = false;
      state.metrics.errors = action.payload.errors;
    },
  },
});

export const { clearErrors } = navSlice.actions;

export const metricsSelector = (state) => state.nav.metrics;

export default navSlice.reducer;
