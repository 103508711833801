import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Statistic } from "antd";
import { useEffect, useState } from "react";

export function Metric(props) {
  const [percent, setPercent] = useState();

  useEffect(() => {
    if (props.old !== undefined) {
      let divisor = props.old ? props.old : 1;
      setPercent(((props.current - props.old) * 100) / divisor);
    }
  }, [props]);

  return (
    <>
      {percent !== undefined ? (
        <div className="stat-card b-shadow">
          <Statistic
            title={props.title}
            value={Math.abs(percent)}
            loading={props.loading}
            precision={0}
            valueStyle={{
              color: percent > 0 ? "green" : "red",
            }}
            suffix="%"
            prefix={percent > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
          />
        </div>
      ) : (
        <div className="stat-card b-shadow">
          <Statistic
            title={props.title}
            value={props.current}
            precision={0}
            loading={props.loading}
          />
        </div>
      )}
    </>
  );
}
