import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  loggedIn: false,
  currentUser: {},
  loading: false,
  validateLoading: true,
  errors: null,
};

export const login = createAsyncThunk("user/login", async (data, thunk) => {
  try {
    let response = await API.auth.login(data);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const validate = createAsyncThunk("user/validate", async (_, thunk) => {
  try {
    let response = await API.auth.validate();
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getUser = createAsyncThunk("user/sessions", async (_, thunk) => {
  try {
    let response = await API.auth.getUser();
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const logout = createAsyncThunk("user/logout", async (_, thunk) => {
  try {
    let response = await API.auth.logout();
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const update = createAsyncThunk("user/update", async (data, thunk) => {
  try {
    let response = await API.auth.update(data);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loggedIn = false;
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      state.loggedIn = true;
      state.currentUser = action.payload;
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload?.errors
        ? action.payload?.errors
        : ["You are not authorized to use this dasbhoard"];
    },
    [validate.pending]: (state) => {
      state.validateLoading = true;
    },
    [validate.fulfilled]: (state, action) => {
      state.validateLoading = false;
      state.currentUser = action.payload;
    },
    [validate.rejected]: (state, action) => {
      state.validateLoading = false;
      state.errors = action.payload?.errors;
    },
    [getUser.pending]: (state) => {
      state.loading = true;
    },
    [getUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
    },
    [getUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload?.errors;
    },
    [update.pending]: (state) => {
      state.loading = true;
    },
    [update.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
    },
    [update.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload?.errors.full_messages;
    },
  },
});

export const { clearErrors } = authSlice.actions;

export const authSelector = (state) => state.auth;

export default authSlice.reducer;
