import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  waitlist: [],
  totalPages: 0,
  totalCount: 0,
  loading: false,
  errors: null,
};

export const getWaitlist = createAsyncThunk(
  "waitlist/index",
  async (data, thunk) => {
    try {
      let response = await API.waitlist.index(data.page);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const waitlistSlice = createSlice({
  name: "waitlist",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getWaitlist.pending]: (state) => {
      state.loading = true;
    },
    [getWaitlist.fulfilled]: (state, action) => {
      state.loading = false;
      state.waitlist = action.payload.waitlist;
      state.totalPages = action.payload.total_pages;
      state.totalCount = action.payload.total_count;
    },
    [getWaitlist.rejected]: (state, action) => {
      state.loading = false;
      state.waitlist = [];
      state.totalPages = 0;
      state.errors = ["Error during data retrieval!"];
    },
  },
});

export const { clearErrors } = waitlistSlice.actions;

export const waitlistSelector = (state) => state.waitlist;

export default waitlistSlice.reducer;
