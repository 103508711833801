import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../../../api";

const initialState = {
  data: [],
  totalPages: 0,
  totalCount: 0,
  loading: false,
  errors: null,
};

export const getInferences = createAsyncThunk(
  "users/getInferences",
  async (data, thunk) => {
    try {
      let response = await API.users.inferences(data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const inferencesSlice = createSlice({
  name: "inferences",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getInferences.pending]: (state) => {
      state.loading = true;
    },
    [getInferences.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.inferences;
      state.totalCount = action.payload.total_count;
      state.totalPages = action.payload.total_pages;
    },
    [getInferences.rejected]: (state, action) => {
      state.loading = false;
      state.data = [];
      state.totalPages = 0;
      state.errors = ["Data not found!"];
    },
  },
});

export const { clearErrors } = inferencesSlice.actions;

export const inferencesSelector = (state) => state.inferences;

export default inferencesSlice.reducer;
