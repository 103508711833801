import { ConfigProvider } from "antd";
import moment from "moment-timezone";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./authInterceptor";
import "./common.css";
import "./index.css";
import "./overrides.css";
import reportWebVitals from "./reportWebVitals";
import { Router } from "./router";
import ScrollToTop from "./scrollTop";
import store from "./store";

moment.tz.setDefault("America/Los_Angeles");

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#4867b1",
        },
      }}
    >
      <BrowserRouter>
        <ScrollToTop />
        <Router />
      </BrowserRouter>
    </ConfigProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
