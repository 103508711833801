import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  requests: {
    data: [],
    loading: false,
    errors: null,
    totalPages: 0,
    totalCount: 0,
  },
};

export const getSupportRequests = createAsyncThunk(
  "support/index",
  async (data, thunk) => {
    try {
      let response = await API.support.index(
        data.page,
        data.per,
        data.search,
        data.filters
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateSupportRequest = createAsyncThunk(
  "support/update",
  async (data, thunk) => {
    try {
      let response = await API.support.update(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.requests.errors = null;
    },
  },
  extraReducers: {
    [getSupportRequests.pending]: (state) => {
      state.requests.loading = true;
    },
    [getSupportRequests.fulfilled]: (state, action) => {
      state.requests.loading = false;
      state.requests.data = action.payload.support_requests;
      state.requests.totalPages = action.payload.total_pages;
      state.requests.totalCount = action.payload.total_count;
    },
    [getSupportRequests.rejected]: (state, action) => {
      state.requests.loading = false;
      state.requests.errors = action.payload;
    },
  },
});

export const supportRequestsSelector = (state) => state.support.requests;

export default supportSlice.reducer;
