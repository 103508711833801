import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  orgs: [],
  loading: false,
  errors: null,
  totalPages: 0,
  totalCount: 0,
  orgsDetails: {},
  updatedOrg: {
    data: {},
    loading: false,
    errors: null,
  },
  orgsList: [],
};

export const getOrgs = createAsyncThunk("getOrgs", async (data, thunk) => {
  try {
    let response = await API.orgs.getOrgs(data.page, data.search);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const listOrgs = createAsyncThunk("listOrgs", async (data, thunk) => {
  try {
    let response = await API.orgs.listOrgs();
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const createOrg = createAsyncThunk("createOrg", async (data, thunk) => {
  try {
    let response = await API.orgs.createOrg(data.data);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const updateOrg = createAsyncThunk("updateOrg", async (data, thunk) => {
  try {
    let response = await API.orgs.updateOrg(data.id, data.data);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getOrgDetails = createAsyncThunk(
  "getOrgDetails",
  async (data, thunk) => {
    try {
      let response = await API.orgs.getOrgDetails(data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const orgsSlice = createSlice({
  name: "orgs",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    clearUpdatedOrg: (state) => {
      state.updatedOrg = {};
    },
  },
  extraReducers: {
    [getOrgs.pending]: (state) => {
      state.loading = true;
    },
    [getOrgs.fulfilled]: (state, action) => {
      state.loading = false;
      state.orgs = action.payload.orgs;
      state.totalPages = action.payload.total_pages;
      state.totalCount = action.payload.total_count;
    },
    [getOrgs.rejected]: (state, action) => {
      state.loading = false;
      state.orgs = [];
      state.totalPages = 0;
      state.errors = ["Error during data retrieval!"];
    },
    [getOrgDetails.pending]: (state) => {
      state.loading = true;
    },
    [getOrgDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.orgsDetails = action.payload;
    },
    [getOrgDetails.rejected]: (state, action) => {
      state.loading = false;
      state.orgsDetails = {};
      state.errors = ["Error during data retrieval!"];
    },
    [createOrg.pending]: (state) => {
      state.updatedOrg.loading = true;
    },
    [createOrg.fulfilled]: (state, action) => {
      state.updatedOrg.loading = false;
      state.updatedOrg.data = action.payload;
    },
    [createOrg.rejected]: (state, action) => {
      state.updatedOrg.loading = false;
      state.updatedOrg.data = {};
      state.updatedOrg.errors = ["Something went wrong. Try again later!"];
    },
    [updateOrg.pending]: (state) => {
      state.updatedOrg.loading = true;
    },
    [updateOrg.fulfilled]: (state, action) => {
      state.updatedOrg.loading = false;
      state.updatedOrg.data = action.payload;
    },
    [updateOrg.rejected]: (state, action) => {
      state.updatedOrg.loading = false;
      state.updatedOrg.data = {};
      state.updatedOrg.errors = ["Something went wrong. Try again later!"];
    },
    [listOrgs.fulfilled]: (state, action) => {
      state.orgsList = action.payload.orgs;
    },
  },
});

export const { clearErrors, clearUpdatedOrg } = orgsSlice.actions;

export const orgsSelector = (state) => state.orgs;
export const orgsUpdateSelector = (state) => state.orgs.updatedOrg;

export default orgsSlice.reducer;
