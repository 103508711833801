import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  all: {
    data: [],
    loading: false,
    errors: null,
    totalPages: 0,
    totalCount: 0,
  },
  current: {
    data: {},
    loading: false,
    errors: null,
  },
};

export const getNodes = createAsyncThunk("nodes/index", async (data, thunk) => {
  try {
    let response = await API.nodes.index(data.page);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getNode = createAsyncThunk("nodes/show", async (data, thunk) => {
  try {
    let response = await API.nodes.show(data.id);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const updateNode = createAsyncThunk(
  "nodes/update",
  async (data, thunk) => {
    try {
      let response = await API.nodes.update(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const nodesSlice = createSlice({
  name: "nodes",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getNodes.pending]: (state) => {
      state.all.loading = true;
    },
    [getNodes.fulfilled]: (state, action) => {
      state.all.loading = false;
      state.all.data = action.payload.nodes;
      state.all.totalPages = action.payload.total_pages;
      state.all.totalCount = action.payload.total_count;
    },
    [getNodes.rejected]: (state, action) => {
      state.all.loading = false;
      state.all.totalPages = 0;
      state.all.errors = ["Error during data retrieval!"];
    },
    [getNode.pending]: (state) => {
      state.current.loading = true;
    },
    [getNode.fulfilled]: (state, action) => {
      state.current.loading = false;
      state.current.data = action.payload;
    },
    [getNode.rejected]: (state, action) => {
      state.current.loading = false;
      state.current.errors = ["Error during data retrieval!"];
    },
    [updateNode.pending]: (state) => {
      state.current.loading = true;
    },
    [updateNode.fulfilled]: (state, action) => {
      state.current.loading = false;
      state.current.data = action.payload;
    },
    [updateNode.rejected]: (state, action) => {
      state.current.loading = false;
      state.current.errors = action.payload.errors;
    },
  },
});

export const { clearErrors } = nodesSlice.actions;

export const allNodesSelector = (state) => state.nodes.all;
export const currentNodeSelector = (state) => state.nodes.current;

export default nodesSlice.reducer;
