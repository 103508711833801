import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  conversations: {
    data: [],
    loading: false,
    errors: null,
    totalPages: 0,
    totalCount: 0,
  },
  chats: {
    data: [],
    loading: false,
    errors: null,
  },
  events: {
    data: [],
    loading: false,
    errors: null,
  },
  currentConversation: {
    data: {},
    loading: false,
    errors: null,
  },
};

export const getConversations = createAsyncThunk(
  "conversations/index",
  async (data, thunk) => {
    try {
      let response = await API.conversations.index(
        data.page,
        data.search,
        data.filters
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getConversation = createAsyncThunk(
  "conversations/show",
  async (data, thunk) => {
    try {
      let response = await API.conversations.show(data.conversationId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const deleteConversation = createAsyncThunk(
  "conversations/delete",
  async (data, thunk) => {
    try {
      let response = await API.conversations.delete(data.conversationId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getChats = createAsyncThunk("chats/index", async (data, thunk) => {
  try {
    let response = await API.chats.index(data.conversationId);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getEvents = createAsyncThunk(
  "events/index",
  async (data, thunk) => {
    try {
      let response = await API.conversations.events.index(data.conversationId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const conversationsSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    clearConversations: (state) => {
      state.conversations.data = [];
    },
    clearChats: (state) => {
      state.chats.data = [];
    },
    clearEvents: (state) => {
      state.chats.events = [];
    },
    clearCurrentConversation: (state) => {
      state.currentConversation.data = {};
    },
  },
  extraReducers: {
    [getConversations.pending]: (state) => {
      state.conversations.loading = true;
    },
    [getConversations.fulfilled]: (state, action) => {
      state.conversations.loading = false;
      state.conversations.data = action.payload.conversations;
      state.conversations.totalPages = action.payload.total_pages;
      state.conversations.totalCount = action.payload.total_count;
    },
    [getConversations.rejected]: (state, action) => {
      state.conversations.loading = false;
      state.conversations.data = [];
      state.conversations.totalPages = 0;
      state.conversations.errors = ["Error during data retrieval!"];
    },
    [getChats.pending]: (state) => {
      state.chats.loading = true;
    },
    [getChats.fulfilled]: (state, action) => {
      state.chats.loading = false;
      state.chats.data = action.payload.chats;
    },
    [getChats.rejected]: (state, action) => {
      state.chats.loading = false;
      state.chats.errors = ["Error during data retrieval!"];
    },
    [getEvents.pending]: (state) => {
      state.events.loading = true;
    },
    [getEvents.fulfilled]: (state, action) => {
      state.events.loading = false;
      state.events.data = action.payload.events;
    },
    [getEvents.rejected]: (state, action) => {
      state.events.loading = false;
      state.events.errors = ["Error during data retrieval!"];
    },
    [getConversation.pending]: (state) => {
      state.currentConversation.loading = true;
    },
    [getConversation.fulfilled]: (state, action) => {
      state.currentConversation.loading = false;
      state.currentConversation.data = action.payload;
    },
    [getConversation.rejected]: (state, action) => {
      state.currentConversation.loading = false;
      state.currentConversation.errors = ["Error during data retrieval!"];
    },
  },
});

export const {
  clearErrors,
  clearConversations,
  clearChats,
  clearEvents,
  clearCurrentConversation,
} = conversationsSlice.actions;

export const conversationsSelector = (state) =>
  state.conversations.conversations;
export const chatsSelector = (state) => state.conversations.chats;
export const eventsSelector = (state) => state.conversations.events;
export const currentConversationSelector = (state) =>
  state.conversations.currentConversation;

export default conversationsSlice.reducer;
