import { message, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Models } from "../../../../constants";
import { getInferences, inferencesSelector } from "./inferencesSlice";

const PAGE_SIZE = 25;

export function Inferences(props) {
  const dispatch = useDispatch();
  const { data, loading, totalPages, totalCount, errors } =
    useSelector(inferencesSelector);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(getInferences({ id: props.userId, page: pageNum }));
  }, [props.userId]);

  const showUsage = (model, usage) => {
    return (
      usage && (
        <>
          <div className="fs-1 fw-500">{Models[model]}</div>
          <div className="fs-1">
            <div>p: {usage.p}</div>
            <div>c: {usage.c}</div>
          </div>
        </>
      )
    );
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
    },
    {
      title: "From Chat ID",
      dataIndex: "from_chat_id",
      key: "from_chat_id",
    },
    {
      title: "To Chat ID",
      dataIndex: "to_chat_id",
      key: "to_chat_id",
    },
    {
      title: "Usage",
      dataIndex: "usage",
      key: "usage",
      render: (usage) => {
        return usage.map((u, i) => {
          return <div key={i}>{showUsage(u["model"], u["token_usage"])}</div>;
        });
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => <div>{new Date(date).toLocaleString()}</div>,
    },
  ];

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(getInferences({ id: props.userId, page: page }));
  };

  return (
    <>
      <Table
        loading={loading}
        bordered
        size="small"
        columns={columns}
        dataSource={data.map((l, i) => {
          return { ...l, ...{ key: i } };
        })}
        pagination={{
          position: ["bottomRight"],
          onChange: onPageChange,
          pageSize: PAGE_SIZE,
          total: totalPages * PAGE_SIZE,
          showSizeChanger: false,
          current: pageNum,
          showTotal: (total) => (
            <span className="fs-2 c-subtext">{`${totalCount} ${
              totalCount === 1 ? "inference" : "inferences"
            }`}</span>
          ),
        }}
      />
    </>
  );
}
