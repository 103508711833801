import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  data: [],
  totalPages: 0,
  totalCount: 0,
  loading: false,
  errors: null,
};
export const searchNodes = createAsyncThunk("search", async (data, thunk) => {
  try {
    let response = await API.discover.search(
      data.query,
      data.categories,
      data.states,
      data.page,
      data.per
    );
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const discoverSlice = createSlice({
  name: "discover",
  initialState,
  reducers: {
    clearNodes: (state) => {
      state.data = [];
    },
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [searchNodes.pending]: (state) => {
      state.loading = true;
    },
    [searchNodes.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.results.map((s) => ({
        ...s,
        key: s.ein,
      }));
      state.totalPages = action.payload.total_pages;
      state.totalCount = action.payload.total_count;
    },
    [searchNodes.rejected]: (state, action) => {
      state.loading = false;
      state.data = [];
      state.totalPages = 0;
      state.totalCount = 0;
      state.errors = ["Invalid search query"];
    },
  },
});

export const { clearNodes, clearErrors } = discoverSlice.actions;

export const discoverSelector = (state) => state.discover;

export default discoverSlice.reducer;
